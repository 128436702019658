body {
    margin: 0;
    padding: 0;
    font-family: 'Roboto', sans-serif!important;

}

* {
    font-family: 'Roboto', sans-serif!important;
}

.loginPage {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;

    form {
        width: 300px;
    }
}

.layoutCustom {
    display: flex;
    min-height: 100vh;

    .sidebar {
        width: 250px;
        background-color: #333;
        color: #fff;
    }

    .content {
        flex: 1;
        background-color: #f4f4f4;
    }
}

.dashboardPage,
.tablePage {
    &>div {
        margin-top: 20px;
        &>div {
            margin-bottom: 20px;
        }
    }
}

.infographicBlock {
    .infographicItem {
        display: flex;
        background-color: #9D968C;
        border-radius: 12px;
        padding: 20px;
        height: 150px;

        h5 {
            margin: 0;
            margin-bottom: 10px;
        }

        .price {
            margin-bottom: 10px;
            display: flex;
            align-items: center;
        }

        .infographicImg {
            display: flex;
            height: 90%;
            align-items: center;
            justify-content: center;
            transition: all 1s ease;

            &:hover {
                // border:6px solid #333;
                // box-shadow: 0px 0px 20px 0 rgba(255,255,255,.5);
                height: 110%;
            }
        }



        .infographicContent {
            display: flex;
            width: 60%;
            flex-direction: column;
            align-items: self-start;
            margin-left: 20px;

            h5 {
                color: #fff;
            }
        }
    }
}


.infographicBlock_history {
    .infographicItem {
        display: flex;
        color: rgb(20, 28, 37) !important;
        padding: 20px;
        height: 150px;

        h5 {
            margin: 0;
            margin-bottom: 10px;
        }

        .price {
            margin-bottom: 10px;
            display: flex;
            align-items: center;
        }

        .infographicImg {
            display: flex;
            height: 90%;
            align-items: center;
            justify-content: center;
            transition: all 1s ease;

            &:hover {
                // border:6px solid #333;
                // box-shadow: 0px 0px 20px 0 rgba(255,255,255,.5);
                height: 110%;
            }
        }



        .infographicContent {
            display: flex;
            width: 60%;
            flex-direction: column;
            align-items: self-start;
            margin-left: 20px;

            h5 {
                color: rgb(20, 28, 37);
            }
        }
    }
}


.headerBalance {
    display: flex;
    align-items: center;

    h2 {
        margin-left: 10px;
        display: flex;
        align-items: center;
    }

    background-color: #141c25;
    height: 45px;
    padding: 10px;
    border-radius: 12px;
    color: #fff;
}


.activeBlock {
    border-radius: 4px;

    .activeBlock_Header {
        border-radius: 4px 4px 0 0;
        color: #fff;
        padding: 10px;
        background-color: #141c25;

        &>div {
            margin-bottom: 10px;
        }

        .activeBlock_Header_Teg {
            padding: 5px;
            background-color: #434C60;
            border-radius: 4px;
            border-bottom: 2px solid;
        }

        .activeBlock_Header_Action_Block {
            padding: 5px;
            border-radius: 4px;
        }
    }

    .activeBlock_Body {
        border: 1px solid #EBE9E4;
        border-radius: 0 0 4px 4px;

        .activeBlock_Body_Item_Active {
            h5 {
                margin: 0;
            }

            .activeBlock_Body_Item_Stat {
                .activeBlock_Body_Item_Stat--topBlock {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;

                    &>div:first-child {
                        width: 70%;
                        padding: 5px;
                        text-align: center;
                        border-right: 1px solid #EBE9E4;
                        border-bottom: 1px solid #EBE9E4;
                    }

                    &>div:last-child {
                        width: 30%;
                        padding: 5px;
                        text-align: center;
                        border-bottom: 1px solid #EBE9E4;
                    }
                }

                .activeBlock_Body_Item_Stat--bottomBlock {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;

                    &>div:first-child {
                        width: 70%;
                        padding: 5px;
                        text-align: center;
                        border-right: 1px solid #EBE9E4;
                        border-bottom: 1px solid #EBE9E4;
                    }

                    &>div:last-child {
                        width: 30%;
                        padding: 5px;
                        text-align: center;
                        border-bottom: 1px solid #EBE9E4;
                    }
                }
            }
        }

        .activeBlock_Body_Item_Profit {
            background-color: #9D968C;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            padding: 10px;
        }

        .historyActiveBlock_Body_Item_Profit {
            background-color: #166534;
            color: #fff;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            padding: 10px;
        }

        .activeBlock_Body_Item_Countdown-Time {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            padding: 10px;
            border-bottom: 1px solid #9D968C;
        }


        .activeBlock_Body_Item_Countdown {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            padding: 10px;
            border-bottom: 3px solid #166534;
        }

        .activeBlock_Body_Item {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            grid-template-rows: repeat(5, 1fr);
            grid-column-gap: 0px;
            grid-row-gap: 0px;
            text-align: center;

            .activeBlock_Body_Item_Center {
                grid-area: 5 / 1 / 6 / 3;
            }

            .activeBlock_Body_Item_Left {
                grid-area: 1 / 1 / 5 / 2;
                display: flex;
                flex-direction: column;

                &>div {
                    padding: 10px;
                    width: 100%;
                    border-bottom: 1px solid #EBE9E4;
                }
            }

            .activeBlock_Body_Item_Right {
                grid-area: 1 / 2 / 5 / 3;
                display: flex;
                flex-direction: column;

                &>div {
                    padding: 10px;
                    width: 100%;
                    border-bottom: 1px solid #EBE9E4;
                    border-left: 1px solid #EBE9E4;
                }
            }

            .activeBlock_Body_Item_Right_Price {
                display: flex;
                flex-direction: column;
            }

            .activeBlock_Body_Item_Center {
                border-bottom: 1px solid #EBE9E4;
                display: flex;
                align-items: center;
                justify-content: center;
            }
        }

        .activeBlock_Body_Footer {
            text-transform: uppercase;
            font-size: 12px;

            &>div:first-child {
                background-color: #9D968C;
                color: #fff;
            }

            .activeBlock_Body_Footer--top {
                display: flex;
                align-items: center;
                justify-content: space-between;
                height: 45px;
                padding: 10px;
                cursor: pointer;
                background-color: #9D968C;
                color: #fff;
            }

            &>div {
                display: flex;
                align-items: center;
                justify-content: space-between;
                height: 45px;
                padding: 10px;
                cursor: pointer;
            }

            &>div:last-child {
                border-radius: 0 0 4px 4px;
            }
        }

        // div {
        //     display: flex;
        //     align-items: center;
        //     justify-content: center;
        //     border: 1px solid #EBE9E4;
        // }
    }
}

.circle-segment-container {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}


.activeBlock_Header_Action {
    .confirm {
        background-color: #166534;
    }

    .cancel {
        background-color: #651616;
    }

    .waiting {
        background-color: #dda138;
    }
}

.activeBlock_Header_Tegs {
    .Equity {
        border-color: aqua !important;
    }
    .Credit {
        border-color: blue !important;
    }
    .Multi-Asset {
        border-color: brown !important;
    }
    .Bond {
        border-color: green !important;
    }
    .RealEstate {
        border-color: darkred !important;
    }
    .Commodity {
        border-color: gold !important;
    }
    .Currency {
        border-color: purple !important;
    }
    .Infrastructure {
        border-color: teal !important;
    }
    .VentureCapital {
        border-color: orange !important;
    }
    .PrivateEquity {
        border-color: pink !important;
    }
    .HedgeFund {
        border-color: silver !important;
    }
    .FixedIncome {
        border-color: olive !important;
    }
    .Derivatives {
        border-color: navy !important;
    }
    .GrowthEquity {
        border-color: lime !important;
    }
    .DistressedDebt {
        border-color: maroon !important;
    }
    .HighYield {
        border-color: violet !important;
    }
    .SovereignDebt {
        border-color: magenta !important;
    }
    .CorporateDebt {
        border-color: coral !important;
    }
    .ConvertibleDebt {
        border-color: cyan !important;
    }
    .PreferredEquity {
        border-color: khaki !important;
    }
}




.hovered {
    transform-origin: center;
}

.activeBlockDetail {
    border-radius: 4px;


    .activeBlock_Body_Item_Title,
    .activeBlock_Body_History_Title {
        background-color: #141c25;
        padding: 10px;
        color: #fff;
        border-top: 1px solid #fff;
    }

    .activeBlock_Body_History_Title {
        border-left: 1px solid #fff;
    }

    .activeBlock_Header {
        border-radius: 4px 4px 0 0;
        color: #fff;
        padding: 10px;
        background-color: #141c25;

        &>div {
            margin-bottom: 10px;
        }

        .activeBlock_Header_Teg {
            padding: 5px;
            background-color: #434C60;
            border-radius: 4px;
            border-bottom: 2px solid;
        }

        .activeBlock_Header_Action_Block {
            padding: 5px;
            border-radius: 4px;
        }
    }

    .activeBlock_Body {
        border: 1px solid #EBE9E4;
        border-radius: 0 0 4px 4px;
        display: flex;
        justify-content: space-between;

        .activeBlock_Body_Item {
            text-align: center;
            width: 50%;
            border-right: 1px solid #EBE9E4;
            display: flex;
            flex-direction: column;

            .activeBlock_Body_Item_Table_Item {
                display: flex;
                justify-content: space-between;
                border-bottom: 1px solid #EBE9E4;

                &>div {
                    width: 50%;
                    padding: 10px;

                }
            }




            .activeBlock_Body_Item_Center {
                border-bottom: 1px solid #EBE9E4;
                display: flex;
                align-items: center;
                justify-content: center;
            }

            .activeBlock_Body_Item_Center {
                padding: 10px;
                flex: 1;

            }
        }

        .activeBlock_Body_Footer {
            &>a>div:first-child {
                background-color: #9D968C;
                color: #fff;
            }

            &>div:first-child {
                background-color: #9D968C;
                color: #fff;
            }

            .activeBlock_Body_Footer--top {
                display: flex;
                align-items: center;
                justify-content: space-between;
                height: 45px;
                padding: 10px;
                cursor: pointer;
            }

            &>div {
                display: flex;
                align-items: center;
                justify-content: space-between;
                height: 45px;
                padding: 10px;
                cursor: pointer;
            }

            &>div:last-child {
                border-radius: 0 0 0px 0px;
            }
        }

        .activeBlock_Body_History {
            width: 50%;

            .activeBlock_Body_History_Items {
                display: flex;
                flex-direction: column;
                align-items: center;
            }

            .divider {
                width: 80%;
                height: 1px;
                background-color: #9D968C;
                text-align: center;
            }

            .activeBlock_Body_History_Item {
                padding: 10px;
                width: 100%;

                &>div {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                }
            }

            .activeBlock_Body_History_Item:last-child {
                display: flex;
                justify-content: space-between;
                border-bottom: none;

            }
        }
    }
}

.formCategoryList {
    display: flex;
    align-items: center;
    padding: 10px;
    border: 1px solid;
    border-radius: 10px;
    height: 50px;
}

.CustomModal {
    label {
        margin-bottom: 10px;
        margin-left: 5px;
    }
}

.HeaderRightMEnu {
    position: absolute;
    right: 20px;
    display: flex;
    height: 100%;
}

.emptyBlock {
    border: 1px solid rgb(231 231 231);
    padding: 10px;
    border-radius: 5px;
}

.ant-input-number-input-wrap {
    height: 100% !important;

    &>input {
        height: 100% !important;
    }
}

.logo {
    display: flex;
    align-items: center;
    justify-content: center;
}

.diverRight {
    border-right: 1px solid #EBE9E4;
}

:where(.css-dev-only-do-not-override-ekaps3).ant-modal .ant-modal-content {
    padding: 0 !important; /* Ваши значения отступов */
  }

.FormSetingClient input{
    border-radius: 40px !important;
}



  .ng-select.ng-select-opened>.ng-select-container {
    background: #fff;
    border-color: #b3b3b3 #ccc #d9d9d9
}

.ng-select.ng-select-opened>.ng-select-container:hover {
    box-shadow: none
}

.ng-select.ng-select-opened>.ng-select-container .ng-arrow {
    top: -2px;
    border-color: transparent transparent #999;
    border-width: 0 5px 5px
}

.ng-select.ng-select-opened>.ng-select-container .ng-arrow:hover {
    border-color: transparent transparent #333
}

.ng-select.ng-select-opened.ng-select-top>.ng-select-container {
    border-top-right-radius: 0;
    border-top-left-radius: 0
}

.ng-select.ng-select-opened.ng-select-right>.ng-select-container {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0
}

.ng-select.ng-select-opened.ng-select-bottom>.ng-select-container {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0
}

.ng-select.ng-select-opened.ng-select-left>.ng-select-container {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0
}

.ng-select.ng-select-focused:not(.ng-select-opened)>.ng-select-container {
    border-color: #007eff;
    box-shadow: inset 0 1px 1px #00000013,0 0 0 3px #007eff1a
}

.ng-select.ng-select-disabled>.ng-select-container {
    background-color: #f9f9f9
}

.ng-select .ng-has-value .ng-placeholder {
    display: none
}

.ng-select .ng-select-container {
    color: #333;
    background-color: #fff;
    border-radius: 4px;
    border: 1px solid #ccc;
    min-height: 36px;
    align-items: center
}

.ng-select .ng-select-container:hover {
    box-shadow: 0 1px #0000000f
}

.ng-select .ng-select-container .ng-value-container {
    align-items: center;
    padding-left: 10px
}

[dir=rtl] .ng-select .ng-select-container .ng-value-container {
    padding-right: 10px;
    padding-left: 0
}

.ng-select .ng-select-container .ng-value-container .ng-placeholder {
    color: #999
}

.ng-select.ng-select-single .ng-select-container {
    height: 36px
}

.ng-select.ng-select-single .ng-select-container .ng-value-container .ng-input {
    top: 5px;
    left: 0;
    padding-left: 10px;
    padding-right: 50px
}

[dir=rtl] .ng-select.ng-select-single .ng-select-container .ng-value-container .ng-input {
    padding-right: 10px;
    padding-left: 50px
}

.ng-select.ng-select-multiple.ng-select-disabled>.ng-select-container .ng-value-container .ng-value {
    background-color: #f9f9f9;
    border: 1px solid #e6e6e6
}

.ng-select.ng-select-multiple.ng-select-disabled>.ng-select-container .ng-value-container .ng-value .ng-value-label {
    padding: 0 5px
}

.ng-select.ng-select-multiple .ng-select-container .ng-value-container {
    padding-top: 5px;
    padding-left: 7px
}

[dir=rtl] .ng-select.ng-select-multiple .ng-select-container .ng-value-container {
    padding-right: 7px;
    padding-left: 0
}

.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value {
    font-size: .9em;
    margin-bottom: 5px;
    color: #333;
    background-color: #ebf5ff;
    border-radius: 2px;
    margin-right: 5px
}

[dir=rtl] .ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value {
    margin-right: 0;
    margin-left: 5px
}

.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value.ng-value-disabled {
    background-color: #f9f9f9
}

.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value.ng-value-disabled .ng-value-label {
    padding-left: 5px
}

[dir=rtl] .ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value.ng-value-disabled .ng-value-label {
    padding-left: 0;
    padding-right: 5px
}

.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value .ng-value-label,.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value .ng-value-icon {
    display: inline-block;
    padding: 1px 5px
}

.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value .ng-value-icon:hover {
    background-color: #d1e8ff
}

.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value .ng-value-icon.left {
    border-right: 1px solid #b8dbff
}

[dir=rtl] .ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value .ng-value-icon.left {
    border-left: 1px solid #b8dbff;
    border-right: none
}

.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value .ng-value-icon.right {
    border-left: 1px solid #b8dbff
}

[dir=rtl] .ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value .ng-value-icon.right {
    border-left: 0;
    border-right: 1px solid #b8dbff
}

.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-input {
    padding: 0 0 3px 3px
}

[dir=rtl] .ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-input {
    padding: 0 3px 3px 0
}

.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-input>input {
    color: #000
}

.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-placeholder {
    top: 5px;
    padding-bottom: 5px;
    padding-left: 3px
}

[dir=rtl] .ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-placeholder {
    padding-right: 3px;
    padding-left: 0
}

.ng-select .ng-clear-wrapper {
    color: #999
}

.ng-select .ng-clear-wrapper:hover .ng-clear {
    color: #d0021b
}

.ng-select .ng-clear-wrapper:focus .ng-clear {
    color: #d0021b
}

.ng-select .ng-clear-wrapper:focus {
    outline: none
}

.ng-select .ng-spinner-zone {
    padding: 5px 5px 0 0
}

[dir=rtl] .ng-select .ng-spinner-zone {
    padding: 5px 0 0 5px
}

.ng-select .ng-arrow-wrapper {
    width: 25px;
    padding-right: 5px
}

[dir=rtl] .ng-select .ng-arrow-wrapper {
    padding-left: 5px;
    padding-right: 0
}

.ng-select .ng-arrow-wrapper:hover .ng-arrow {
    border-top-color: #666
}

.ng-select .ng-arrow-wrapper .ng-arrow {
    border-color: #999 transparent transparent;
    border-style: solid;
    border-width: 5px 5px 2.5px
}

.ng-dropdown-panel {
    background-color: #fff;
    border: 1px solid #ccc;
    box-shadow: 0 1px #0000000f;
    left: 0
}

.ng-dropdown-panel.ng-select-top {
    bottom: 100%;
    border-top-right-radius: 4px;
    border-top-left-radius: 4px;
    border-bottom-color: #e6e6e6;
    margin-bottom: -1px
}

.ng-dropdown-panel.ng-select-top .ng-dropdown-panel-items .ng-option:first-child {
    border-top-right-radius: 4px;
    border-top-left-radius: 4px
}

.ng-dropdown-panel.ng-select-right {
    left: 100%;
    top: 0;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
    border-bottom-left-radius: 4px;
    border-bottom-color: #e6e6e6;
    margin-bottom: -1px
}

.ng-dropdown-panel.ng-select-right .ng-dropdown-panel-items .ng-option:first-child {
    border-top-right-radius: 4px
}

.ng-dropdown-panel.ng-select-bottom {
    top: 100%;
    border-bottom-right-radius: 4px;
    border-bottom-left-radius: 4px;
    border-top-color: #e6e6e6;
    margin-top: -1px
}

.ng-dropdown-panel.ng-select-bottom .ng-dropdown-panel-items .ng-option:last-child {
    border-bottom-right-radius: 4px;
    border-bottom-left-radius: 4px
}

.ng-dropdown-panel.ng-select-left {
    left: -100%;
    top: 0;
    border-top-left-radius: 4px;
    border-bottom-right-radius: 4px;
    border-bottom-left-radius: 4px;
    border-bottom-color: #e6e6e6;
    margin-bottom: -1px
}

.ng-dropdown-panel.ng-select-left .ng-dropdown-panel-items .ng-option:first-child {
    border-top-left-radius: 4px
}

.ng-dropdown-panel .ng-dropdown-header {
    border-bottom: 1px solid #ccc;
    padding: 5px 7px
}

.ng-dropdown-panel .ng-dropdown-footer {
    border-top: 1px solid #ccc;
    padding: 5px 7px
}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-optgroup {
    -webkit-user-select: none;
    user-select: none;
    padding: 8px 10px;
    font-weight: 500;
    color: #0000008a;
    cursor: pointer
}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-optgroup.ng-option-disabled {
    cursor: default
}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-optgroup.ng-option-marked {
    background-color: #f5faff
}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-optgroup.ng-option-selected,.ng-dropdown-panel .ng-dropdown-panel-items .ng-optgroup.ng-option-selected.ng-option-marked {
    color: #0000008a;
    background-color: #ebf5ff;
    font-weight: 600
}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-option {
    background-color: #fff;
    color: #000000de;
    padding: 8px 10px
}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-selected,.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-selected.ng-option-marked {
    color: #333;
    background-color: #ebf5ff
}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-selected .ng-option-label,.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-selected.ng-option-marked .ng-option-label {
    font-weight: 600
}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-marked {
    background-color: #f5faff;
    color: #333
}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-disabled {
    color: #ccc
}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-child {
    padding-left: 22px
}

[dir=rtl] .ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-child {
    padding-right: 22px;
    padding-left: 0
}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-option .ng-tag-label {
    font-size: 80%;
    font-weight: 400;
    padding-right: 5px
}

[dir=rtl] .ng-dropdown-panel .ng-dropdown-panel-items .ng-option .ng-tag-label {
    padding-left: 5px;
    padding-right: 0
}

[dir=rtl] .ng-dropdown-panel {
    direction: rtl;
    text-align: right
}

@charset "UTF-8";[class^=icon-],[class*=" icon-"] {
    align-items: center;
    border: 0!important;
    display: flex;
    height: 3.8rem;
    margin-left: -1.375rem;
    padding: 0!important;
    transform: scale(.5);
    width: 3.8rem
}

[class^=icon-]:before,[class*=" icon-"]:before {
    align-items: center;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: contain;
    -webkit-mask-position: center center;
    mask-position: center center;
    -webkit-mask-repeat: no-repeat;
    mask-repeat: no-repeat;
    -webkit-mask-size: contain;
    mask-size: contain;
    content: "";
    display: inline-block;
    height: 3.75rem;
    width: 3.75rem;
    background-color: #001eff
}


.fadeinwrapper .isserverside {
    opacity: 0
}

.fadeinwrapper .isclientside {
    animation-duration: .5s;
    animation-iteration-count: 1;
    animation-name: fadeInOpacity;
    animation-timing-function: ease-in
}

@keyframes fadeInOpacity {
    0% {
        opacity: 0
    }

    to {
        opacity: 1
    }
}

@keyframes rotating {
    to {
        transform: rotateY(180deg)
    }
}

@keyframes pulse {
    to {
        opacity: 1
    }
}

@keyframes showup {
    0% {
        opacity: 0
    }

    to {
        opacity: 1
    }
}

body {
    font-size: .875rem
}

.light-font-weight {
    font-weight: 300
}

.regular-font-weight {
    font-weight: 400
}

.medium-font-weight {
    font-weight: 500
}

.semi-bold-font-weight {
    font-weight: 600
}

.bold-font-weight {
    font-weight: 700
}

@media (max-width: 1144px) {
    .bold-font-weight-sm {
        font-weight:700
    }
}

.extrabold-font-weight {
    font-weight: 800
}

.small-font-size {
    font-size: .875rem!important;
    line-height: 1em
}

.smaller-font-size {
    font-size: .65625rem;
    line-height: 1em
}

.base-font-size {
    font-size: .875rem;
    line-height: 1.3125rem
}

@media (max-width: 1144px) {
    .xs-base-font-size {
        font-size:.875rem!important;
        line-height: 1.3125rem!important
    }
}

.regular-font-size {
    font-size: .984375rem;
    line-height: 1.75rem
}

.big-font-size {
    font-size: 1.09375rem;
    line-height: 1.1em
}

.bigger-font-size {
    font-size: 1.203125rem;
    line-height: 1em
}

.generous-font-size {
    font-size: 1.421875rem;
    line-height: 1.640625rem
}

.large-font-size {
    font-size: 1.640625rem;
    line-height: 1em
}

@media (max-width: 1144px) {
    .large-font-size {
        font-size:1.18125rem
    }
}

.larger-font-size {
    font-size: 2.1875rem;
    line-height: 1em
}

@media (max-width: 1144px) {
    .larger-font-size {
        font-size:1.75rem
    }
}

.hero-font {
    font-size: 3.5rem;
    font-weight: 800;
    line-height: 4.046875rem
}

.align-center {
    text-align: center
}

.align-right {
    text-align: right
}

.section-title {
    display: inline
}

.blue-text {
    color: #001eff
}

.grey-medium-text {
    color: #4d4d4d
}

h1 {
    font-size: 1.640625rem;
    font-weight: 700;
    line-height: 1.4;
    font-family: LSEG Motto,Proxima Nova,Arial,Helvetica;
    text-transform: uppercase
}

h2 {
    font-size: 1.3125rem;
    font-weight: 700;
    line-height: 1.333;
    font-family: LSEG Motto,Proxima Nova,Arial,Helvetica;
    text-transform: uppercase
}

h3 {
    font-size: 1.09375rem;
    font-weight: 700;
    line-height: 1.2
}

h4 {
    font-size: .984375rem;
    font-weight: 700;
    line-height: 1.333
}

h5 {
    font-weight: 700
}

.h-link {
    font-size: .984375rem;
    font-weight: 500
}

.h-link-bold {
    font-size: .984375rem;
    font-weight: 700
}

.text-align-center {
    text-align: center
}

.text-align-right {
    text-align: right
}

.text-align-left {
    text-align: left
}

.text-align-justify {
    text-align: justify
}

.full-width {
    max-width: 100%;
    width: 100%
}

.x-large-width {
    max-width: 71.5rem;
    width: 100%
}

@media (max-width: 1144px) {
    .x-large-width-padding {
        padding:0 1rem
    }
}

.large-width {
    max-width: 52.75rem;
    width: 100%
}

@media (max-width: 1144px) {
    .large-width {
        width:72.5%
    }
}

@media (max-width: 768px) {
    .large-width {
        max-width:100%;
        width: 100%
    }
}

.regular-width {
    max-width: 46.5rem;
    width: 100%
}

@media (max-width: 1144px) {
    .regular-width {
        width:65%
    }
}

@media (max-width: 768px) {
    .regular-width {
        max-width:100%;
        width: 100%
    }
}

.small-width {
    max-width: 34rem;
    width: 100%
}

@media (max-width: 1144px) {
    .small-width {
        width:47.5%
    }
}

@media (max-width: 768px) {
    .small-width {
        max-width:100%;
        width: 100%
    }
}

.x-small-width {
    max-width: 21.5rem;
    width: 100%
}

@media (max-width: 1144px) {
    .x-small-width {
        width:30%
    }
}

@media (max-width: 768px) {
    .x-small-width {
        max-width:100%;
        width: 100%
    }
}

.xx-small-width {
    max-width: 15.25rem;
    width: 100%
}

@media (max-width: 1144px) {
    .xx-small-width {
        width:21%
    }
}

@media (max-width: 768px) {
    .xx-small-width {
        float:left;
        max-width: 50%;
        width: 100%
    }
}

@media (max-width: 480px) {
    .xx-small-width {
        max-width:100%
    }
}

.viewport-height {
    min-height: 100vh
}

.wrapper {
    box-sizing: border-box;
    margin: 0 auto
}

.wrapper:after {
    clear: both;
    content: "";
    display: block
}

.floating-component {
    max-width: calc(50% - ((100% - 71.5rem)/2));
    width: 100%
}

@media (max-width: 1144px) {
    .floating-component {
        padding:0 1rem
    }
}

.floating-component,.floating-component~.floating-component~.floating-component {
    float: left;
    margin-left: calc((100% - 71.5rem)/2)
}

@media (max-width: 1144px) {
    .floating-component,.floating-component~.floating-component~.floating-component {
        float:none;
        margin: 0;
        max-width: 100%
    }
}

.floating-component~.floating-component {
    float: right;
    margin-left: 0;
    margin-right: calc((100% - 71.5rem)/2)
}

@media (max-width: 1144px) {
    .floating-component~.floating-component {
        float:none;
        margin: 0;
        max-width: 100%
    }
}

.content-with-sidebar {
    border-right: .063rem solid #e6e6e6;
    display: block;
    float: left;
    margin-left: calc((100% - 71.5rem)/2);
    margin-top: 2rem;
    padding-right: 5rem
}

@media (max-width: 1144px) {
    .content-with-sidebar {
        margin-left:1rem;
        padding-right: 2rem
    }
}

@media (max-width: 768px) {
    .content-with-sidebar {
        border-right:0;
        float: none;
        margin: 0 auto;
        padding: 0 1rem;
        width: 100%
    }
}

.sidebar {
    display: block;
    float: right;
    margin-bottom: 1rem;
    margin-right: calc((100% - 71.5rem)/2);
    padding-top: 2rem
}

@media (max-width: 1144px) {
    .sidebar {
        margin-right:1rem
    }
}

@media (max-width: 768px) {
    .sidebar {
        float:none;
        margin: 0 1rem;
        max-width: calc(100% - 2rem);
        width: 100%
    }
}

.sidebar~.sidebar {
    border-top: .125rem solid #fafafa
}

.calendar-item-content {
    display: block;
    float: left;
    margin-left: calc((100% - 71.5rem)/2);
    margin-right: 2.5rem
}

@media (max-width: 1144px) {
    .calendar-item-content {
        border-right:0;
        float: none;
        margin: 0 auto;
        padding: 0 1rem;
        width: 100%
    }
}

.calendar-item-sidebar {
    display: block;
    float: right;
    margin-left: 0;
    margin-right: calc((100% - 71.5rem)/2)
}

@media (max-width: 1144px) {
    .calendar-item-sidebar {
        border-right:0;
        float: none;
        margin: 0 auto;
        padding: 0 1rem;
        width: 100%
    }
}

.flex-wrapper {
    display: flex;
    flex-wrap: wrap
}

@media (max-width: 480px) {
    .flex-wrapper {
        display:block
    }
}

.mobile-flex-wrapper {
    display: flex;
    flex-wrap: wrap
}

.flex-container {
    display: flex
}

@media (max-width: 480px) {
    .flex-container {
        display:block
    }
}

.mobile-flex-container {
    display: flex
}

.spaced-flex-container {
    justify-content: space-between
}

.centered-flex-container {
    justify-content: center
}

.flex-align-center {
    align-items: center
}

.flex-align-baseline {
    align-items: baseline
}

.flex-align-end {
    align-items: flex-end
}

.flex-column {
    flex-direction: column
}

.flex-filler {
    flex-grow: 1
}

@media (max-width: 768px) {
    .flex-filler {
        width:100%
    }
}

.flex-content {
    flex: 1 0 auto
}

.flex-shrink-none {
    flex-shrink: 0
}

.vertical-aligned-flex {
    align-items: center
}

ndc-dynamic {
    display: none
}

.show-on-mobile {
    display: none!important
}

@media (max-width: 480px) {
    .show-on-mobile {
        display:block!important
    }
}

@media (max-width: 480px) {
    .hide-on-mobile {
        display:none!important
    }
}

.show-on-mobile-nav {
    display: none!important
}

@media (max-width: 767px) {
    .show-on-mobile-nav {
        display:block!important
    }
}

@media (max-width: 767px) {
    .hide-on-mobile-nav {
        display:none!important
    }
}

.show-on-portrait {
    display: none!important
}

@media (max-width: 768px) {
    .show-on-portrait {
        display:block!important
    }
}

@media (max-width: 768px) {
    .hide-on-portrait {
        display:none!important
    }
}

.show-on-landscape {
    display: none!important
}

@media (max-width: 1023px) {
    .show-on-landscape {
        display:block!important
    }
}

@media (max-width: 1023px) {
    .hide-on-landscape {
        display:none!important
    }
}

.show-on-desktop {
    display: none!important
}

@media (max-width: 1144px) {
    .show-on-desktop {
        display:block!important
    }
}

@media (max-width: 1144px) {
    .hide-on-desktop {
        display:none!important
    }
}

app-mini-bio,app-statutory-block,app-ftse-russell-report,app-company-tearsheet,app-news-table,app-etf-risers-table,app-cta-simple,app-price-explorer {
    display: block;
    margin-top: 1.5rem
}

html.cdk-global-scrollblock .page-header {
    top: 0
}

html {
    min-height: 100vh;
    scroll-behavior: smooth
}

body {
    background: #ffffff;
    font-family: Proxima Nova,Arial,Helvetica;
    line-height: 1.3em;
    min-width: 20rem;
    color: #1a1a1a
}

body.navigation-open,body.block-scroll,body.block-scroll-on-portrait {
    overflow: hidden;
    -webkit-overflow-scrolling: touch
}

@media (min-width: 768px) {
    body.block-scroll-on-portrait {
        overflow:auto
    }
}

* {
    min-height: .0625rem
}

app-tidm-bar {
    min-height: 4rem
}

a {
    color: #001eff;
    text-decoration: underline
}

a:hover {
    color: #001299;
    text-decoration: none
}

.black-link {
    color: #000;
    text-decoration: none
}

.black-link:hover {
    color: #000;
    text-decoration: underline
}

.black-link.link-lists {
    display: block;
    margin-bottom: 1.5rem
}

.blue-link {
    color: #001eff;
    text-decoration: none
}

.blue-link:hover {
    color: #001eff;
    text-decoration: underline
}

.blue-link.link-lists {
    display: block;
    margin-bottom: 1.5rem
}

.white-link {
    color: #fff;
    text-decoration: none
}

.white-link:hover {
    color: #fff;
    text-decoration: underline
}

.white-link.link-lists {
    display: block;
    margin-bottom: 1.5rem
}

.fake-link {
    color: #001eff;
    cursor: pointer;
    text-decoration: underline
}

.fake-link:hover {
    color: #001eff;
    text-decoration: none
}

.item-label {
    margin-bottom: .5em;
    text-transform: capitalize
}

.ellipsed {
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap
}

@media (max-width: 1023px) {
    .ellipsed-below-landscape {
        display:block;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap
    }
}

.more-on-this {
    display: inline-block;
    position: relative
}

.more-on-this a,.more-on-this a:hover {
    border-bottom: .063rem dotted;
    bottom: 0;
    color: inherit;
    display: inline-block;
    height: .25rem;
    left: 0;
    padding-bottom: .025em;
    position: absolute;
    width: 100%
}

.page-header {
    width: 100%;
    z-index: 30
}

.navigation-open .page-header {
    position: fixed
}

.app-handshake.black {
    background: #000000
}

.app-handshake.white {
    background: #ffffff
}

.page-content {
    color: #000;
    display: block;
    position: relative
}

@media (max-width: 1144px) {
    .page-content.change-component-order {
        display:flex;
        flex-direction: column
    }

    .page-content.change-component-order app-calendar-item {
        order: 3
    }

    .page-content.change-component-order app-calendar-widget {
        order: 2
    }
}

.black .page-content {
    color: #fff
}

.page-content p,.page-content li {
    font-size: .984375rem;
    line-height: 1.78em
}

.page-content .grid-card p {
    line-height: 1.5em
}

.component {
    clear: both;
    margin-bottom: 1rem
}

.component-refresh {
    position: relative
}

.component-refresh .spinner {
    left: calc(50% - 1.5rem);
    opacity: 0;
    position: absolute!important;
    top: calc(50% - 1.5rem);
    transform: scale(0);
    z-index: 99
}

.component-refresh.refreshing * {
    opacity: .75;
    transition: opacity .5s linear
}

.component-refresh.refreshing app-spinner,.component-refresh.refreshing .spinner {
    opacity: 1
}

.component-refresh.refreshing .spinner {
    transform: scale(1)
}

img {
    max-width: 100%
}

.clickable {
    cursor: pointer
}

.clickable:hover {
    text-decoration: underline
}

.relative {
    position: relative
}

.high-value {
    color: #22775e
}

.low-value {
    color: #7e1b1b
}

.blue-list,.blue-list ul,.blue-list ol {
    list-style: none
}

.blue-list li,.tick-list li {
    position: relative
}

.blue-list li:before,.tick-list li:before {
    position: absolute
}

.blue-list li:before {
    content: "\2022";
    display: inline-block;
    font-size: 1.3125rem;
    font-weight: 700;
    margin-left: -1em;
    text-indent: .25em;
    top: .0625rem;
    width: 1em
}

.blue-list li:last-child {
    padding-bottom: 0
}

.tick-list,.tick-list ul,.tick-list ol {
    list-style: none
}


.tick-list li:last-child {
    padding-bottom: 0
}

.ghost-button {
    background: #ffffff;
    border: .125rem solid #000c66;
    color: #000c66;
    cursor: pointer;
    display: inline-block;
    font-size: .984375rem;
    line-height: 1.185rem;
    margin-bottom: 1rem;
    padding: .75rem;
    text-align: center;
    text-decoration: none
}

.ghost-button:hover {
    color: #000c66
}

.ghost-button:disabled {
    border-color: #e6e6e6;
    color: #e6e6e6
}

.download-button-with-file-weight {
    align-items: center;
    background-color: #fff;
    border: .0625rem solid #e6e6e6;
    color: #000;
    display: inline-flex;
    flex-wrap: wrap;
    height: 4.5rem;
    justify-content: center;
    margin: .625rem 1.5625rem;
    padding: 0 .4375rem .625rem;
    text-decoration: none;
    width: 9.375rem
}

.download-button-with-file-weight .document-download-icon {
    left: -.625rem;
    position: relative;
    top: .75rem
}

.dash-wrapper {
    color: #000
}

@media print {
    * {
        min-height: auto;
        page-break-inside: auto!important
    }

    html,body {
        height: auto;
        min-height: auto;
        min-width: auto;
        width: 100%
    }

    body.news-article-page .flex-container,body.news-article-page .mobile-flex-container,body.news-article-page .flex-wrapper {
        display: block!important
    }

    body.news-article-page .page-content {
        padding: 0;
        position: static
    }

    body.news-article-page .x-large-width {
        max-width: 90%
    }

    body.news-article-page .news-article-content {
        display: block;
        margin-bottom: 2rem;
        margin-top: 0;
        page-break-inside: auto!important;
        position: static
    }

    body.news-article-page .news-article-back,body.news-article-page .share-wrapper {
        display: none
    }
}

.m-64 {
    margin-bottom: 4rem!important
}

.download-link {
    border-bottom: .063rem solid;
    display: inline-block;
    margin: 0 0 1em .5em;
    padding-left: .5em;
    position: relative;
    text-decoration: none
}

@media (max-width: 480px) {
    .download-link {
        margin:0 1.5em 1em .5em
    }
}


.download-link:hover {
    border-bottom: 0
}


.whats-this {
    color: #001eff;
    margin: 0 .75rem 0 1.75rem;
    position: relative;
    text-decoration: none;
    white-space: nowrap
}

.whats-this:hover {
    color: #001eff;
    text-decoration: underline
}

.gold-button {
    background: #001eff;
    border: .0625rem solid #001eff;
    border-radius: 5px;
    color: #fff;
    cursor: pointer;
    display: inline-block;
    font-size: .875rem;
    line-height: 1.5rem;
    margin-bottom: 1rem;
    padding: .5089rem 1.651785rem;
    text-align: center;
    text-decoration: none;
    font-weight: 700
}

.gold-button * {
    color: #fff!important
}

.gold-button:hover {
    color: #fff;
    background-color: #001299
}

.gold-button:active {
    background: #000c66;
    border-color: #000c66
}

.gold-button:disabled,.gold-button.disabled {
    background: #e6e6e6;
    border-color: #e6e6e6;
    pointer-events: none
}

.gold-button.outline {
    background: #ffffff;
    color: #4d4d4d!important;
    border: .0625rem solid #4D4D4D
}

.gold-button.outline:hover {
    color: #4d4d4d
}

.gold-button.outline:disabled {
    border-color: #4d4d4d;
    color: #4d4d4d
}

.divider {
    clear: both;
    display: block;
    height: .125rem
}

.dropmenu {
    cursor: pointer;
    display: inline-block;
    line-height: 3em;
    max-width: 17.875rem;
    padding: 0 .875em;
    position: relative;
    width: 100%
}

.dropmenu:after {
    display: inline-block;
    margin-top: -.625rem;
    position: absolute;
    right: 1em;
    top: 50%
}

.dropmenu ul {
    display: none;
    list-style: none
}

.social-icon:before,.social-btn:before,.share-service:before {
    display: inline-block
}

.share-services button,.print-button button {
    background: transparent;
    border: 0;
    color: #001eff;
    cursor: pointer;
    float: left;
    height: 1.5rem;
    margin: 0;
    padding: 0;
    width: 1.5rem
}

.share-services button:before,.print-button button:before {
    background-position: bottom center;
    background-repeat: no-repeat;
    background-size: contain;
    content: "";
    display: block;
    height: 1.75rem;
    transform: scale(.5);
    width: 2rem
}

.social-btn {
    display: inline-block;
    height: 1.5rem;
    overflow: hidden;
    position: relative;
    width: 1.5rem
}

.social-btn:before {
    background-repeat: no-repeat;
    background-size: contain;
    content: "";
    height: 3rem;
    position: absolute;
    right: -.75rem;
    top: -.75rem;
    transform: scale(.5);
    width: 3rem
}

.show-more {
    display: block;
    padding-top: 1.5rem;
    text-align: center
}

.show-more-div {
    padding-top: 1.5rem;
    text-align: center
}

.show-more-div>a,.show-more-div>div {
    color: #001eff;
    cursor: pointer;
    display: inline-block;
    padding: 0 .2em 0 1.2em;
    position: relative;
    text-decoration: underline
}


.show-more-div>a:hover,.show-more-div>div:hover {
    text-decoration: none
}

.report-preview {
    border: .063rem solid #b2b2b2;
    float: left;
    height: auto;
    margin: 0 1rem 1rem 0;
    width: 33%
}

.component-header,.spaced-item {
    margin-bottom: 1rem
}

.index-item {
    border-bottom: .063rem solid #e6e6e6;
    margin-bottom: 1rem;
    padding-bottom: 1rem
}

@media (max-width: 768px) {
    .index-item {
        max-width:100%;
        width: 100%
    }
}

.index-item.full-width {
    width: 100%!important
}

.index-item span {
    margin-bottom: .5rem
}

.interactive-outline-wrapper {
    padding: 0
}

.interactive-outline-button {
    background-color: #fff;
    border: .063rem solid #b2b2b2;
    border-radius: .25rem;
    color: #4d4d4d;
    cursor: pointer;
    display: inline-block;
    line-height: 1.2em;
    margin-right: 1rem;
    padding: .75rem;
    text-align: left;
    text-decoration: none
}

.interactive-outline-button a {
    color: #4d4d4d;
    text-decoration: none
}

.interactive-outline-button:last-child {
    margin-right: 0
}

.interactive-outline-button:hover {
    border-color: #001eff;
    color: inherit;
    text-decoration: none
}

.interactive-outline-button:hover a {
    text-decoration: none
}

.interactive-outline-button.active {
    border-color: #001eff;
    color: #001eff;
    font-weight: 700
}

.interactive-outline-button.active a {
    color: #001eff
}

.index-item {
    margin-right: 1%;
    width: 24%
}

@media (max-width: 1023px) {
    .index-item {
        display:block;
        width: 49%
    }
}

@media (max-width: 480px) {
    .index-item {
        margin-right:0;
        width: 100%
    }
}

.dropmenu.expanded:after {
    transform: rotate(180deg)
}

.dropmenu.expanded a,.dropmenu.expanded .fake-link {
    display: block;
    margin: 0 .875em;
    text-decoration: none
}

.dropup.expanded ul {
    bottom: 2rem;
    display: block;
    left: -.063rem;
    margin: 0 0 1em;
    padding: 0;
    position: absolute;
    right: -.063rem
}

.dropdown.expanded ul {
    display: block;
    left: -.063rem;
    margin: 0 0 1em;
    padding: 0;
    position: absolute;
    right: -.063rem;
    top: 3rem
}

.jump-menu {
    align-items: center;
    justify-content: center
}

@media (max-width: 768px) {
    .jump-menu {
        flex-direction:column
    }
}

.dropmenu-label {
    margin-right: 1em
}

@media (max-width: 768px) {
    .dropmenu-label {
        margin:0 0 1em
    }
}

.jump-menu .dropup,.jump-menu .dropdown {
    border: .063rem solid #ffffff;
    color: #fff
}

@media (max-width: 768px) {
    .jump-menu .dropup,.jump-menu .dropdown {
        display:block;
        margin: 0 0 1em
    }
}

.jump-menu .dropdown:after,.jump-menu .dropup:after {
    background-position: center center;
    background-repeat: no-repeat;
    background-size: contain;
    content: "";
    height: 1.3rem;
    position: absolute;
    transform: scale(.5);
    width: 1.3rem
}


.jump-menu .dropup {
    background: #000000
}

.jump-menu .dropdown.expanded ul {
    border: .063rem solid #ffffff
}

.jump-menu .dropup.expanded ul {
    background: #000000;
    border: .063rem solid #ffffff
}

.jump-menu a,.jump-menu .fake-link {
    border-bottom: .063rem solid;
    color: #fff
}

.jump-menu a:hover,.jump-menu .fake-link:hover {
    text-decoration: underline
}

.jump-menu li:last-child a,.jump-menu li:last-child .fake-link {
    border: 0
}

.card {
    border-color: #e6e6e6;
    border-left: .25rem solid;
    border-radius: 0;
    height: 10rem;
    margin: 0 0 1.5rem;
    max-width: 22.5rem;
    padding: 1rem
}

@media (max-width: 1023px) {
    .card.x-small-width {
        max-width:100%;
        width: 49%
    }
}

@media (max-width: 768px) {
    .card.x-small-width {
        max-width:100%;
        width: 100%
    }
}

.card a {
    text-decoration: none
}

.card a:hover {
    text-decoration: underline
}

.card.spacer {
    border: 0;
    height: 0
}

.cards-container {
    clear: both;
    padding-top: 1rem
}

.name-container {
    margin-bottom: .85rem
}

.card-name {
    color: #001eff;
    margin-right: .5rem;
    text-decoration: none
}

.card-name:hover {
    text-decoration: underline
}

.card-data {
    margin-top: .3rem
}

@media (max-width: 768px) {
    .swipable-card-container {
        height:12.5rem;
        overflow: hidden;
        position: relative;
        touch-action: pan-y!important
    }

    .swipable-card {
        position: absolute;
        transition: position,.5s ease-in-out;
        z-index: 10
    }

    .swipable-card.current {
        left: 0
    }

    .swipable-card.after {
        left: 100%
    }

    .swipable-card.before {
        left: -100%
    }
}


.image-strip:before {
    background-color: #fff3;
    content: "";
    display: block;
    inset: 0;
    position: absolute
}

.image-strip .wrapper {
    position: relative
}


.panel h3 {
    font-size: 1.09375rem;
    margin-bottom: .3125rem
}

.index-filter {
    background-color: #fafafa;
    box-shadow: 0 .125rem #b2b2b2;
    color: #000;
    overflow: hidden;
    padding: .75rem 0
}

@media (max-width: 768px) {
    .index-filter .wrapper {
        box-sizing:content-box;
        height: 100%;
        overflow-x: scroll;
        overflow-y: hidden;
        padding: 0 0 3rem;
        white-space: nowrap;
        width: 100%
    }

    .index-filter .wrapper .interactive-outline-button {
        box-sizing: border-box;
        margin: 0 0 0 1rem;
        min-height: 2.75rem
    }

    .index-filter .wrapper .interactive-outline-button:last-child {
        margin-right: 1rem
    }
}

.filters-header {
    background: #001eff;
    color: #fff;
    padding: .5rem 1rem
}

.filters-container {
    margin-top: 1.5rem
}

.filters-container+app-filters-toggle .filters-container,.filters-container+.filters-container {
    margin-top: 0
}

.filters-container+app-filters-toggle .filters-container .filters-header,.filters-container+.filters-container .filters-header {
    background: #ffffff;
    border: solid;
    border-color: #b2b2b2;
    border-width: 0 .0625rem;
    color: #000;
    padding: .5rem 1rem
}

.filters-container+app-filters-toggle .filters-container .index-filter,.filters-container+.filters-container .index-filter {
    padding-top: 1.4rem;
    position: relative
}

.filters-container+app-filters-toggle .filters-container .index-filter:before,.filters-container+.filters-container .index-filter:before {
    border-left: 1.2rem solid transparent;
    border-right: 1.2rem solid transparent;
    border-top: 1.2rem solid #ffffff;
    content: "";
    display: inline-block;
    height: 0;
    left: 50%;
    margin-left: -.6rem;
    position: absolute;
    top: 0;
    width: 0
}

section:not(.price-explorer-component) .filters-container:last-child {
    margin-bottom: 1.5rem
}

section:not(.price-explorer-component) .filters-container:last-child .index-filter {
    border-width: 0 .0625rem .0625rem!important
}

.filters-container.index-filter.alphabet {
    border-width: 0 .0625rem .0625rem!important;
    margin-bottom: 1.5rem;
    margin-top: 0
}

.index-filter {
    border: solid;
    border-color: #b2b2b2;
    border-width: 0 .0625rem;
    box-shadow: none;
    padding: .875rem .75rem 0
}

.index-filter .interactive-outline-button {
    margin-bottom: 1rem
}

.owl-dt-container-info,.owl-dt-calendar-cell-out,.owl-dt-calendar-table-divider {
    display: none!important
}

.owl-dt-calendar-event {
    background-color: #001eff!important;
    border-radius: 50%;
    display: block;
    height: .5rem;
    left: 1.625rem;
    position: relative;
    top: -2.25rem;
    width: .5rem
}

.owl-dt-calendar-table {
    border-collapse: initial;
    border-top: .0625rem solid #b2b2b2;
    margin-bottom: 1rem;
    table-layout: fixed
}

.owl-dt-calendar-table * {
    box-sizing: initial
}

.owl-dt-calendar-table th,.owl-dt-calendar-table td {
    border: 0;
    float: left;
    width: 14.25%
}

.owl-dt-calendar-table tr {
    display: block
}

.owl-dt-calendar-table th {
    padding: .5rem 0!important;
    text-align: center
}

.owl-dt-calendar-table tbody {
    white-space: nowrap
}

.owl-dt-calendar-table .owl-dt-calendar-cell {
    display: inline-block;
    font-size: .875rem;
    font-weight: 700;
    height: 2.5rem
}

.owl-dt-calendar-multi-year-table thead,.owl-dt-control-arrow-button[disabled] {
    display: none;
    opacity: 0
}

.owl-dt-calendar-table .owl-dt-calendar-cell-content {
    border-radius: 50%;
    height: 2.5rem;
    margin: 0 auto;
    position: static;
    width: 100%
}

.owl-dt-calendar-table.owl-dt-calendar-year-table,.owl-dt-calendar-view.owl-dt-calendar-multi-year-view {
    align-items: initial
}

.owl-dt-calendar-table.owl-dt-calendar-year-table .owl-dt-calendar-cell-content,.owl-dt-calendar-view.owl-dt-calendar-multi-year-view .owl-dt-calendar-cell-content {
    height: 3rem;
    width: 3rem
}

.owl-dt-calendar-table .owl-dt-calendar-cell-today:not(.owl-dt-calendar-cell-selected) {
    border-color: #001eff
}

.owl-dt-calendar-cell-disabled .owl-dt-calendar-cell-content {
    cursor: default
}

.owl-dt-calendar-table .owl-dt-calendar-cell-selected {
    background: #001eff!important;
    color: #fff!important
}

.owl-dt-calendar-table .owl-dt-calendar-cell:not(.owl-dt-calendar-cell-disabled):hover .owl-dt-calendar-cell-content {
    background: #001eff!important;
    color: #fff!important
}

.owl-dt-calendar-main {
    padding: 0
}

.owl-dt-weekdays {
    background: #ffffff;
    color: #000
}

.owl-dt-calendar-table .owl-dt-calendar-header .owl-dt-weekdays th {
    font-weight: 700;
    text-transform: uppercase
}

.owl-dt-calendar-table .owl-dt-calendar-cell-in-range {
    background: transparent
}

.owl-dt-calendar-table .owl-dt-calendar-cell-in-range:not(.owl-dt-calendar-cell-range-from):not(.owl-dt-calendar-cell-range-to) span {
    background: #e6e6e6;
    border-radius: 0
}

.owl-dt-calendar-table .owl-dt-calendar-cell-in-range.owl-dt-calendar-cell-range-from span {
    border-bottom-right-radius: 0;
    border-top-right-radius: 0
}

.owl-dt-calendar-table .owl-dt-calendar-cell-in-range.owl-dt-calendar-cell-range-to span {
    border-bottom-left-radius: 0;
    border-top-left-radius: 0
}

.owl-dt-calendar-table .owl-dt-calendar-cell-in-range.owl-dt-calendar-cell-range-from.owl-dt-calendar-cell-range-to span {
    border-radius: 50%
}

.owl-dt-control-button.owl-dt-control-arrow-button .owl-dt-control-button-content {
    display: none
}


.owl-dt-control-arrow-button .owl-dt-control-button-content {
    height: 1.375rem;
    width: 1.375rem
}

.owl-dt-calendar-table .owl-dt-calendar-cell-content.owl-dt-calendar-cell-today {
    background: #ffffff;
    border: 0;
    color: #000
}

.owl-dt-control-period-button {
    font-weight: 700
}

.owl-dt-popup-container {
    top: .0625rem
}

.owl-dt-calendar-view .owl-dt-control-button {
    position: absolute;
    top: .5rem
}

.owl-dt-calendar-view .owl-dt-control-button:first-of-type {
    left: .5rem
}

.owl-dt-calendar-view .owl-dt-control-button:last-of-type {
    right: .5rem
}

.owl-dt-calendar-multi-year-view .owl-dt-calendar-table {
    width: 100%
}

.owl-dt-calendar-table.owl-dt-calendar-multi-year-table .owl-dt-calendar-cell-in-range,.owl-dt-calendar-table.owl-dt-calendar-year-table .owl-dt-calendar-cell-in-range {
    background: transparent
}

.owl-dt-calendar-table .owl-dt-calendar-cell-selected.owl-dt-calendar-cell-today {
    box-shadow: none
}

.owl-dt-calendar-table .owl-dt-calendar-cell {
    padding: 0!important
}

.owl-dt-calendar-table.owl-dt-calendar-multi-year-table {
    padding-top: .5rem
}

@media (max-width: 480px) {
    .cdk-overlay-pane {
        left:.5rem!important;
        margin: 0!important;
        width: calc(100% - 1rem)!important
    }

    .cdk-overlay-pane .owl-dt-inline-container,.cdk-overlay-pane .owl-dt-popup-container {
        width: 100%
    }
}

.owl-dt-calendar-table .owl-dt-calendar-cell-content.owl-dt-calendar-cell-today:not(.owl-dt-calendar-cell-selected) {
    background: transparent
}

.total-results {
    color: #001eff
}

@media (max-width: 480px) {
    .total-results {
        display:block;
        margin-bottom: 0;
        margin-right: 0;
        padding-right: 1.875rem
    }

    .total-results:after {
        right: 0;
        top: .45rem
    }
}

.filters-list {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    width: auto
}

@media (max-width: 768px) {
    .filters-list {
        display:flex
    }
}

.filter-filtered {
    display: flex;
    flex-grow: 0;
    justify-content: space-between;
    width: auto
}

.filter-filtered .fake-link {
    margin: 0 0 0 .75rem
}

@media (max-width: 768px) {
    .filter-filtered .fake-link {
        display:block;
        margin-bottom: 1.5rem
    }
}

.filter-filtered .total-results {
    display: inline;
    margin-right: 1.5rem
}

.chart-tabs {
    border-bottom: .0625rem solid #e6e6e6;
    display: flex;
    margin: 0 0 2rem;
    padding: 0;
    width: 100%
}

.chart-tab {
    border-right: .0625rem solid #e6e6e6;
    color: #4d4d4d;
    cursor: pointer;
    display: block;
    flex-basis: 0;
    flex-grow: 1;
    padding: 1.5rem 1rem .75rem;
    text-align: center
}

.chart-tab:hover {
    color: #001eff;
    text-decoration: underline
}

.chart-tab.active {
    border-bottom: .5rem solid #001eff;
    color: #001eff;
    padding-bottom: .25rem
}

.chart-tab:last-child {
    border-right: 0
}

#chart-license-text {
    display: none
}

.filters-list>div {
    margin-bottom: .5rem
}

.filters-list .total-results {
    margin-right: 2rem
}

.indented {
    display: inline-flex
}

.uppercase {
    text-transform: uppercase
}

input::-ms-clear {
    display: none
}

ul.base-list {
    list-style-type: none;
    padding-left: 3.5rem
}

ul.base-list li {
    margin-top: 2.375rem;
    position: relative
}

ul.base-list li:before {
    background-color: #001eff;
    border-radius: 50%;
    content: "";
    display: block;
    height: .5rem;
    left: -1rem;
    position: absolute;
    top: .4rem;
    width: .5rem
}

.player-responsive {
    margin-bottom: 1rem;
    max-width: 100%;
    overflow: hidden;
    padding-bottom: 56%;
    position: relative
}

.player-responsive .player-content {
    height: 100%;
    inset: 0;
    position: absolute;
    width: 100%
}

.player-text {
    margin: 1rem auto
}

.table-in-rich-text {
    width: 100%
}

.table-in-rich-text .black-link {
    white-space: normal
}

@media (max-width: 480px) {
    .table-in-rich-text .black-link {
        word-break:break-all
    }
}

.rich-text-wrapper iframe {
    height: 52vw;
    max-height: 35rem;
    width: 100%
}

.rich-text-wrapper.column *:last-child {
    margin-bottom: 0
}

.rich-text-wrapper.additional-content h1,.rich-text-wrapper.additional-content h2,.rich-text-wrapper.additional-content h3,.rich-text-wrapper.additional-content h4 {
    font-size: 1.09375rem;
    font-weight: 700;
    width: 100%
}

.glossary-promo-text a,.glossary-term-description a {
    color: #000;
    font-weight: 700;
    text-decoration: none
}

.glossary-promo-text a:hover,.glossary-term-description a:hover {
    text-decoration: underline
}

#assessment-tool.resize-height .bx-viewport {
    min-height: 1100px!important
}

#assessment-tool .final-inputs .col label.error {
    color: #7e1b1b;
    margin-top: .5rem
}

.search-page .header-navigation .mobile-flex-container {
    max-height: 1.75rem
}

.search-page .page-header .logo {
    margin-top: 1.75rem
}

.search-page .header-navigation-white {
    background-color: transparent!important
}

.search-page .page-content {
    margin-top: -1.75rem;
    padding-top: 0
}

.search-page .page-header,.contextual-help-page .page-header,.news-article-page .page-header {
    position: static
}

.search-page app-breadcrumb,.contextual-help-page app-breadcrumb,.news-article-page app-breadcrumb,.news-article-page app-page-footer {
    display: none
}

#contextual-help .rich-text-wrapper {
    font-size: .875rem
}

#contextual-help .rich-text-wrapper p {
    font-size: .875rem;
    margin-bottom: 2.5rem
}

#contextual-help .rich-text-wrapper td {
    border-bottom: 0
}

#contextual-help .rich-text-wrapper td:first-child {
    font-size: .984375rem;
    font-weight: 700;
    padding-left: 0;
    vertical-align: text-top;
    width: 14.125rem
}

.contextual-help-page .page-content,.news-article-page .page-content {
    padding-top: 2rem
}

#calendar-widget owl-date-time-inline {
    background-color: #fafafa;
    color: #000;
    padding: 1.5rem
}

#calendar-widget .owl-dt-container {
    z-index: 0
}

#calendar-widget .owl-dt-inline-container {
    box-shadow: none
}
@media (max-width: 768px) {
    .gold-button.close-button {
        margin:1rem 0;
        order: 1
    }
}

.pages-tags .pages-tag {
    background-color: #001eff;
    border-radius: .3rem;
    color: #fff;
    display: inline-block;
    margin-right: .5rem;
    margin-top: .5rem;
    padding: .35rem .3rem
}

.alphabet-container {
    border-bottom: .0625rem solid #b2b2b2
}

.alphabet-container .filters-header {
    background-color: #fafafa;
    border: solid;
    border-color: #b2b2b2;
    border-width: 0 .0625rem;
    box-shadow: none;
    color: #000;
    display: flex;
    height: 6.375rem;
    justify-content: center;
    min-height: 6.375rem;
    padding: 0 .75rem
}

.alphabet-filters {
    border: 0;
    box-shadow: none;
    padding: 0 6.6875rem;
    text-align: left
}

@media (max-width: 480px) {
    .alphabet-filters {
        margin-left:-1rem;
        margin-right: -1rem;
        padding: 0 2vw;
        text-align: center
    }
}

.alphabet-filters a {
    background-color: #fff;
    border: .0625rem solid #b2b2b2;
    color: #000;
    cursor: default;
    display: inline-block;
    height: 2.5rem;
    line-height: 2.5rem;
    margin: 0 .4rem .8rem;
    opacity: .5;
    pointer-events: none;
    text-align: center;
    text-decoration: none;
    width: 2.5rem
}

@media (max-width: 480px) {
    .alphabet-filters a {
        height:10vw;
        line-height: 10vw;
        margin-left: 2vw;
        margin-right: 2vw;
        width: 10vw
    }
}

.alphabet-filters a.active {
    cursor: pointer;
    opacity: 1;
    pointer-events: all
}

.alphabet-filters a.active:hover,.alphabet-filters a.active:focus {
    border-color: #001eff
}

.alphabet-filters a.active.current {
    border-color: #001eff;
    color: #001eff
}

.category-grid {
    padding-top: 1.5rem
}

.grid-link {
    border: .0625rem solid #e6e6e6;
    box-shadow: .0625rem .0625rem 3px #0000001a;
    color: #001eff;
    cursor: pointer;
    font-size: .984375rem;
    font-weight: 700;
    margin: 0 2rem 1.5rem 0;
    padding: .5rem 1.5rem 2rem;
    text-align: center;
    width: calc(25% - 1.5rem)
}

@media (max-width: 768px) {
    .grid-link {
        width:calc(50% - 1rem)!important
    }

    .grid-link:nth-child(2n) {
        margin-right: 0
    }
}

.grid-link:nth-child(4n) {
    margin-right: 0
}

.grid-link:hover,.grid-link.active-grid {
    border: .0625rem solid #001eff
}

.category-grid-card2 .grid-link {
    width: calc(50% - 1rem)
}

.category-grid-card2 .grid-link:nth-child(2) {
    margin-right: 0
}

.category-grid-card3 {
    justify-content: space-between
}

.category-grid-card3 .grid-link {
    width: calc(33% - 1.2rem)
}

.category-grid-card3 .grid-link:nth-child(3) {
    margin-right: 0
}

#category-grid-summary .grid-icon,#category-grid-summary .grid-icon:before {
    height: 7.5rem;
    margin: -.5rem auto;
    max-width: 7.5rem;
    width: 100%
}

#category-grid-summary .grid-icon .icon-benchmark:before,#category-grid-summary .grid-icon:before .icon-benchmark:before {
    background-size: 96%
}

.tab-nav-component {
    background: linear-gradient(180deg,#fff 2.5rem,#d5d5d5 .25rem);
    height: 2.75rem;
    overflow-y: hidden
}

.toggle-list-container {
    height: 2.75rem;
    margin-bottom: 1rem
}

.tab-nav-overlay {
    position: relative
}

@media (max-width: 1023px) {
    .tab-nav-overlay.scrollable:after {
        background:linear-gradient(270deg,#ffffff 1rem,rgba(255,255,255,0) 3rem);
        bottom: -.25rem;
        content: "";
        display: block;
        position: absolute;
        right: 0;
        top: 0;
        width: 3rem;
        z-index: 15
    }

    .tab-nav-overlay.scrollable:before {
        background: linear-gradient(90deg,#ffffff 1rem,rgba(255,255,255,0) 3rem);
        bottom: -.25rem;
        content: "";
        display: block;
        left: 0;
        position: absolute;
        top: 0;
        width: 3rem;
        z-index: 15
    }
}

.tab-nav-container {
    box-sizing: content-box;
    height: 100%;
    overflow-x: scroll;
    overflow-y: hidden;
    padding: 0;
    white-space: nowrap;
    width: 100%
}

.tab-nav-wrapper {
    display: inline-block;
    list-style-type: none;
    margin: 0;
    padding: 0
}

.tab-nav {
    border-bottom: .25rem solid #b2b2b2;
    box-sizing: border-box;
    display: inline-block;
    flex-shrink: 0;
    height: 2.75rem;
    min-width: 10.625rem;
    padding: .5rem .75rem;
    text-align: center
}

@media (max-width: 1023px) {
    .tab-nav {
        position:relative
    }
}

.tab-nav:first-child {
    z-index: 16
}

.tab-nav:last-child {
    margin-right: 0;
    z-index: 16
}

.tab-nav.active {
    border-color: #001eff;
    color: #001eff
}

.tab-nav a {
    color: inherit;
    text-decoration: none;
    width: 100%
}

.tab-nav a:hover {
    text-decoration: underline
}

app-hero~app-tab-nav,app-filter-toggle>app-scrolling-ticker,app-ftse-hero~app-filter-toggle,app-turquoise-hero~app-tab-nav,app-turquoise-hero~app-filter-toggle,app-non-lse-instruments-hero~app-tab-nav,app-non-lse-instruments-hero~app-filter-toggle,app-tab-nav~app-filter-toggle {
    display: block;
    margin-top: -1rem
}

app-download-document~app-download-document {
    display: block;
    margin-top: -3rem
}

.content-module h1 {
    margin-bottom: 3rem
}

.content-module p {
    margin-bottom: 1rem
}

.content-module *:last-child {
    margin-bottom: 0
}

.truncated-text * {
    margin: 0;
    padding: 0;
    text-align: left!important
}

.truncated-text * p {
    min-height: unset
}

#explore-stories-filter .truncated-text *:not(h1):not(h2):not(h3):not(h4):not(h5):not(h6):not(div):not(p):not(span):not(a):not(b):not(strong):not(i):not(em):not(mark):not(small):not(del):not(ins):not(sub):not(sup):not(ul):not(ol):not(li) {
    display: none!important
}

#explore-stories-filter .truncated-text .trunk-char {
    display: inline!important
}

.custom-lse-tooltip {
    background-color: #001eff;
    border-radius: 0!important;
    box-shadow: 0 .25rem .5rem #0006;
    font-family: Proxima Nova,Arial,Helvetica!important;
    font-size: .875rem;
    font-weight: 700;
    margin: .25rem!important;
    max-width: unset!important;
    padding: .5rem .75rem!important
}

.cdk-overlay-container {
    z-index: 16!important
}

.news-body-content h1,.news-body-content h2,.news-body-content h3,.news-body-content h4,.news-body-content h5,.news-body-content h6,.news-body-content p,.news-body-content li,.news-body-content div,.news-body-content span {
    line-height: normal;
    margin: auto;
    margin: initial;
    padding: initial
}

.news-body-content table {
    background-color: initial;
    border-collapse: initial;
    border-spacing: initial;
    color: initial
}

.news-body-content th,.news-body-content td {
    border: initial;
    box-sizing: content-box;
    height: auto;
    height: initial;
    padding: initial
}

.roundel {
    display: inline-block;
    height: 4rem;
    margin: 0 1.25rem 1.25rem 0;
    width: 4rem
}

@media (max-width: 480px) {
    .roundel {
        height:2.5rem;
        margin: 0 .5rem .5rem 0;
        width: 2.5rem
    }
}

.roundel:before {
    background-position: center center;
    background-repeat: no-repeat;
    background-size: contain;
    content: "";
    display: block;
    height: 8rem;
    margin-left: -50%;
    margin-top: -50%;
    transform: scale(1.2);
    width: 8rem
}

@media (max-width: 480px) {
    .roundel:before {
        height:5rem;
        width: 5rem;
        transform: scale(.9)
    }
}


.rns-regulatory-roundel {
    height: 6rem;
    width: 6rem
}

.ftse250-roundel:before {
    background-image: ""
}

.ftse-small-roundel:before {
    background-image: ""
}

.ftse-aim50-roundel:before {
    background-image: ""
}

.ftse-aim100-roundel:before {
    background-image: ""
}

.form-group {
    line-height: 1em;
    margin: 2rem auto
}

.has-error input,.has-error textarea {
    border: .063rem solid #7e1b1b
}

.has-error input:hover,.has-error textarea:hover,.has-error .ng-select-container,.has-error .ng-dropdown-panel {
    border-color: #7e1b1b
}

.has-error .alert {
    color: #7e1b1b;
    padding: .5rem 0
}

.form-control {
    border-radius: 0;
    box-sizing: border-box;
    color: #000;
    font-size: .984375rem;
    height: 3.125rem;
    line-height: 1em;
    padding: .9375rem 1rem
}

.form-control:hover {
    border: .063rem solid #001eff
}

.form-control:focus {
    border: .063rem solid #001eff;
    box-shadow: 0 0 0 .1rem #001eff40
}

.form-control::placeholder {
    color: #999
}

textarea.form-control {
    height: auto;
    line-height: 1.25;
    resize: none
}

.form-check {
    margin-top: 1.5rem;
    padding-left: 1.3125rem
}

.radio,input[type=radio] {
    display: none
}

.radio+label,input[type=radio]+label {
    cursor: pointer;
    padding: .5rem;
    position: relative
}

.radio+label:after,input[type=radio]+label:after {
    background-position: center;
    background-repeat: no-repeat;
    background-size: 1rem 1rem;
    border-radius: 100%;
    content: "";
    display: block;
    height: .5rem;
    left: -1.0625rem;
    margin: 0;
    position: absolute;
    top: 1.3125rem;
    transform: translateY(-50%);
    transition: all .15s ease-in-out 0s;
    width: .5rem;
    z-index: 2
}

.radio+label:before,input[type=radio]+label:before {
    background-position: center;
    background-repeat: no-repeat;
    background-size: 1rem 1rem;
    border: .063rem solid #b2b2b2;
    border-radius: 100%;
    content: "";
    display: block;
    height: 1rem;
    left: -1.3125rem;
    margin: 0;
    position: absolute;
    top: 1.3125rem;
    transform: translateY(-50%);
    transition: all .15s ease-in-out 0s;
    width: 1rem;
    z-index: 1
}

.radio:hover+label:before,input[type=radio]:hover+label:before {
    border-color: #001eff
}

.radio:checked+label:after,input[type=radio]:checked+label:after {
    background-color: #001eff
}

.checkbox,input[type=checkbox] {
    display: none
}

.checkbox+label,input[type=checkbox]+label {
    cursor: pointer;
    padding: .5rem;
    position: relative
}

@media (max-width: 768px) {
    .reverse-checkbox {
        display:block;
        margin-top: 1rem
    }
}

.reverse-checkbox input[type=checkbox]+label {
    margin-bottom: 0;
    margin-right: 2rem;
    padding-left: 0
}

.reverse-checkbox input[type=checkbox]+label:before {
    left: auto;
    right: -1.3125rem;
    top: 50%;
    transform: translateY(-50%)
}

label+input {
    margin-top: .5rem
}

.form-inline button {
    margin: 0
}

.form-inline label+input {
    margin-top: 0
}

.ng-select.form-select {
    border: 0;
    border-radius: 0;
    color: #000;
    min-height: 0;
    padding: 0
}

.ng-select.form-select .ng-select-container {
    border-radius: 0;
    height: 3.125rem;
    min-height: 0;
    padding: .9375rem 1rem
}

.ng-select.form-select .ng-arrow-wrapper {
    margin-left: 1rem;
    padding: 0;
    width: auto
}

.ng-select.form-select.ng-select-opened .ng-select-container {
    background-color: #001eff;
    border: .063rem solid #001eff;
    color: #fff;
    font-weight: 700
}

.ng-select.form-select.ng-select-single .ng-value-container {
    height: 3.125rem;
    padding: .9375rem 0
}

.ng-select.form-select.ng-select-single .ng-value-container .ng-input {
    padding: .9375rem 1rem;
    top: 0
}

.ng-select.form-select .ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-marked {
    background-color: #ccd2ff
}

.ng-select.form-select .ng-dropdown-panel.ng-select-bottom,.ng-select.form-select .ng-dropdown-panel.ng-select-top {
    border-radius: 0;
    height: auto;
    line-height: 2rem;
    padding: 1.5rem 0
}

.ng-select.form-select.select-secondary .ng-select-container {
    height: 2.5rem;
    padding: .5rem .75rem
}

.ng-select.form-select.select-secondary.ng-select-single .ng-value-container {
    height: 2.5rem;
    padding: .5rem 0
}

.ng-select.form-select.select-secondary.ng-select-single .ng-value-container .ng-input {
    padding: .5rem .75rem
}

.ng-select.form-select.select-secondary .ng-dropdown-panel.ng-select-bottom,.ng-select.form-select.select-secondary .ng-dropdown-panel.ng-select-top {
    padding: 1rem 0
}

.ng-select-multiple .ng-option {
    padding: 0!important
}

.ng-select-multiple .ng-option .select-option {
    padding: 8px 16px 8px 22px!important
}

.ng-select-multiple .ng-option .select-option:before {
    border: .0625rem solid #b2b2b2;
    content: "";
    display: inline-block;
    height: 1rem;
    margin-right: .5rem;
    transition: all .15s ease-in-out 0s;
    width: 1rem
}

.ng-select-multiple .ng-option .select-option:hover:before {
    border: .0625rem solid #001eff
}
.ng-select-multiple .ng-value-label,.ng-select-multiple .ng-value-icon {
    color: #000
}

.ng-select-multiple .ng-value-container .ng-value,.ng-select-multiple .ng-clear-wrapper {
    display: none
}

.price-explorer-component .ng-option .select-option:before,.news-table-component .ng-option .select-option:before {
    margin-top: .3rem;
    vertical-align: top
}

.price-explorer-component .ng-option .option-label,.news-table-component .ng-option .option-label {
    display: inline-block;
    white-space: normal
}

.sort-by {
    align-items: flex-end;
    display: flex;
    justify-content: flex-end;
    margin-bottom: 1rem
}

@media (max-width: 1023px) {
    .sort-by {
        display:block
    }

    .sort-by form {
        margin-top: 1rem
    }

    .sort-by .form-group {
        margin: 1.5rem auto;
        width: 100%
    }

    .sort-by .ng-select {
        min-width: 100%
    }
}

.sort-by .form-inline .form-control {
    width: 18.125em
}

.sort-by .form-group {
    margin-top: 0
}

.ng-select.ng-select-opened .ng-select-container,.ng-dropdown-panel {
    z-index: 16!important
}

.trades-filers .ng-select,.recent-issues-header .ng-select,.ftse-index-table-header .ng-select {
    min-width: 12rem
}

.trades-filers .ng-select .ng-dropdown-panel,.recent-issues-header .ng-select .ng-dropdown-panel,.ftse-index-table-header .ng-select .ng-dropdown-panel {
    min-width: 100%
}

.input-time {
    position: relative
}

.input-time .form-control {
    appearance: textfield;
    padding: 1.125rem 1rem .75rem;
    text-align: center;
    width: 4rem
}

.input-time .form-control.has-error {
    border-color: #7e1b1b;
    color: #7e1b1b;
    font-weight: 700
}

.input-time input::-webkit-outer-spin-button,.input-time input::-webkit-inner-spin-button {
    appearance: none;
    margin: 0
}

.input-time+.input-time {
    margin-left: .5rem
}

.input-time+.input-time:before {
    color: #001eff;
    content: ":";
    display: block;
    height: 100%;
    left: -.5rem;
    line-height: 3.125rem;
    position: absolute;
    text-align: center;
    width: .5rem
}

.ng-select.form-select .ng-dropdown-panel.ng-select-bottom,.ng-select.form-select .ng-dropdown-panel.ng-select-top,.ng-select.form-select,.ng-select.form-select.ng-select-opened .ng-select-container,.ng-select .ng-select-container {
    max-width: 100%;
    width: 24rem!important
}

@media (max-width: 768px) {
    .ng-select.form-select .ng-dropdown-panel.ng-select-bottom,.ng-select.form-select .ng-dropdown-panel.ng-select-top,.ng-select.form-select,.ng-select.form-select.ng-select-opened .ng-select-container,.ng-select .ng-select-container {
        width:100%!important
    }
}

#assessment-tool .ng-select.form-select .ng-dropdown-panel.ng-select-bottom,#assessment-tool .ng-select.form-select .ng-dropdown-panel.ng-select-top,#assessment-tool .ng-select.form-select,#assessment-tool .ng-select.form-select.ng-select-opened .ng-select-container,#assessment-tool .ng-select .ng-select-container {
    width: 100%!important
}

.filters-container .ng-dropdown-panel .ng-dropdown-panel-items {
    max-height: 30rem
}

.filters-container .ng-select {
    float: left
}

.ng-placeholder {
    top: 1rem!important
}

.ng-optgroup {
    line-height: 1.5em;
    padding: 8px 10px 8px 22px!important;
    position: relative!important
}


.ng-optgroup .ng-option {
    background: transparent!important;
    margin: 0 0 0 1.75rem
}

.active-dropdown-filter .ng-optgroup:hover,.active-dropdown-filter .ng-optgroup:hover .ng-option {
    background: #a9c8e6
}

#filterdropdown-headlines-2,#filterdropdown-headlines-2.ng-select.form-select,#filterdropdown-headlines-2.ng-select.form-select .ng-select-container,#filterdropdown-headlines-2.ng-select.form-select .ng-dropdown-panel.ng-select-bottom,#filterdropdown-headlines-2.ng-select.form-select .ng-dropdown-panel.ng-select-top,#filterdropdown-headlines-3,#filterdropdown-headlines-3.ng-select.form-select,#filterdropdown-headlines-3.ng-select.form-select .ng-select-container,#filterdropdown-headlines-3.ng-select.form-select .ng-dropdown-panel.ng-select-bottom,#filterdropdown-headlines-3.ng-select.form-select .ng-dropdown-panel.ng-select-top,#filterdropdown-headlines-5,#filterdropdown-headlines-5.ng-select.form-select,#filterdropdown-headlines-5.ng-select.form-select .ng-select-container,#filterdropdown-headlines-5.ng-select.form-select .ng-dropdown-panel.ng-select-bottom,#filterdropdown-headlines-5.ng-select.form-select .ng-dropdown-panel.ng-select-top {
    width: 27.5rem!important
}

.hide-input-submit {
    border: 0;
    bottom: 0;
    height: 0!important;
    left: 0;
    margin: 0;
    opacity: 0;
    padding: 0;
    pointer-events: none;
    position: absolute;
    width: 0!important
}

trades-filers .ng-dropdown-panel .ng-dropdown-panel-items {
    max-height: 16rem!important
}

@media (min-width: 480px) {
    .form-inline .form-group,.form-inline label {
        display:flex;
        align-items: center;
        margin-bottom: 0
    }
}

.overlay {
    background: rgba(0,0,0,.5)
}

.overlay-container {
    display: none;
    inset: 0;
    overflow-y: scroll;
    position: fixed;
    z-index: 99
}

.modal-open .overlay-container {
    display: block
}

.open-modal {
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    padding: 2rem
}

.open-modal .overlay-wrapper {
    background: #ffffff;
    max-width: 100%;
    padding: 1.5rem;
    width: 80vw
}

.overlay-wrapper {
    margin: 1.5rem auto;
    max-width: 54rem;
    position: relative;
    width: 90%
}

.overlay-content {
    background: linear-gradient(180deg,rgba(255,255,255,.85) 0,#ffffff 12.5rem);
    padding: 1.125rem
}


.modal-overlay {
    inset: 0;
    position: fixed
}

@media (max-width: 768px) {
    .modal-video {
        padding:1rem .5rem
    }
}

.modal-video .overlay-wrapper {
    max-height: 90vh;
    overflow-y: auto
}

@media (max-width: 768px) {
    .modal-video .overlay-wrapper {
        padding:1rem .5rem;
        width: 100%
    }
}

.modal-video .close-icon {
    height: 2.5rem;
    position: relative
}

.modal-video .close-icon .close-overlay {
    right: 0;
    top: 0
}

.company-logo {
    align-items: center;
    background: #ffffff;
    border: .0625rem solid #fafafa;
    clear: both;
    height: 9.25rem;
    margin-right: 1.25rem;
    padding: .5rem;
    text-align: center;
    width: 15rem
}

@media (max-width: 1023px) {
    .company-logo {
        height:5.25rem;
        margin-right: .5rem;
        width: 8.75rem
    }
}

.company-logo img {
    height: auto;
    margin: auto;
    max-height: 8.125rem;
    max-width: 13.875rem;
    width: auto
}

@media (max-width: 1023px) {
    .company-logo img {
        max-height:4.125rem;
        max-width: 7.625rem
    }
}

.instrument-label {
    background: #000000;
    color: #fff;
    display: inline-block;
    margin-right: .25rem;
    padding: .5em .75em
}

.bondinstrument .instrument-label {
    background: #472574
}

.equityinstrument .instrument-label {
    background: #22775e
}

.etinstrument .instrument-label {
    background: #7e1b1b
}

.othersinstrument .instrument-label {
    background: #4d4d4d
}

.turquoiseinstrument .instrument-label {
    background: #138386
}

strong.bondinstrument {
    color: #472574
}

strong.equityinstrument {
    color: #22775e
}

strong.etinstrument {
    color: #7e1b1b
}

strong.othersinstrument {
    color: #4d4d4d
}

strong.turquoiseinstrument {
    color: #138386
}

.positivechange {
    color: #22775e
}

.negativechange {
    color: #7e1b1b;
    line-height: 1em
}

.instrument-tidm {
    color: #001eff
}

.rns-source .source-label {
    background: #000c66;
    color: #fff
}

.rnsreach-source .source-label {
    background: #997800;
    color: #fff
}

.source-label {
    display: inline-block;
    padding: .5em .75em
}

.accordion-container {
    margin-bottom: 2.5rem
}

.accordion-container:last-child {
    margin-bottom: 0
}

.accordion-header {
    background: #e6e6e6;
    border: .125rem solid #e6e6e6;
    color: #000;
    cursor: pointer;
    font-size: .984375rem;
    padding: 1.125rem 2%
}

.accordion-header:hover {
    border: .125rem solid #001eff
}

.accordion-title {
    margin-right: 1rem
}

.accordion-description {
    color: #4d4d4d
}

.accordion-toggler {
    float: right;
    position: relative
}


.accordion-content {
    display: none
}

.active-accordion>.accordion-content {
    display: block
}

.back-to-top {
    border: 2px solid #001eff;
    border-radius: 50%;
    bottom: 0;
    cursor: pointer;
    height: 3rem;
    position: absolute;
    right: -4rem;
    width: 3rem
}

@media (max-width: 81.25rem) {
    .back-to-top {
        margin:0 auto;
        position: static
    }
}

// table {
//     background-color: #fff;
//     border-collapse: separate;
//     border-spacing: 0;
//     color: #000
// }

// th,td {
//     border-bottom: .125rem solid #fafafa;
//     box-sizing: border-box;
//     padding: .5rem .5rem .5rem 0
// }

// td {
//     height: 3.25rem
// }

// th:first-child,td:first-child {
//     padding-left: .85rem
// }

.autospace {
    white-space: nowrap;
    width: .063rem
}

.table-header {
    background: #fafafa;
    line-height: 1.25rem
}

.swipable-table th a:not(.whats-this) {
    color: #000;
    font-weight: 700;
    text-decoration: none
}

.swipable-table th a.whats-this {
    font-weight: 400
}

.swipable-table th,.swipable-table td {
    box-sizing: border-box;
    padding: .5rem
}

.swipable-table {
    margin-bottom: 1rem
}

@media (max-width: 1023px) {
    .swipable-table {
        border-bottom:.125rem solid #fafafa;
        border-top: .125rem solid #fafafa;
        display: block;
        overflow: hidden;
        position: relative
    }

    .swipable-table tbody {
        display: flex;
        margin-left: 7.75rem;
        min-height: 16rem;
        position: relative;
        touch-action: pan-y!important;
        -webkit-user-select: text;
        user-select: text
    }

    .swipable-table tr {
        display: inline-block;
        min-width: 7.75rem;
        width: 20%
    }

    .swipable-table td {
        height: auto;
        line-height: 1.25rem
    }

    .swipable-table thead,.swipable-table thead tr {
        height: 100%
    }

    .swipable-table tr.table-header {
        background: #ffffff;
        border-right: .125rem solid #fafafa;
        overflow: hidden;
        position: absolute;
        width: 7rem;
        z-index: 12
    }

    .swipable-table th,.swipable-table td {
        border: 0;
        display: block;
        padding: .5rem 0;
        text-align: left!important;
        -webkit-user-select: text;
        user-select: text
    }

    .swipable-table .track-this .track-toggle {
        left: 0;
        right: auto;
        transform: translateY(-140%)!important
    }

    .swipable-table .track-this .track-toggle:after {
        left: calc(18% - .5rem)!important
    }

    .swipable-table tbody tr {
        background: #ffffff;
        border-right: .125rem solid #fafafa;
        min-width: 100%;
        opacity: 0;
        padding-left: .75rem;
        width: calc(100% + .125rem)
    }

    .swipable-table tbody tr.active-panel {
        inset: 0;
        opacity: 1;
        position: absolute;
        z-index: 10
    }

    .swipable-table .left-animation {
        animation: slide-left .5s forwards
    }

    .swipable-table .right-animation {
        animation: slide-right .5s forwards
    }
}

@keyframes slide-left {
    0% {
        border-left: .125rem solid #fafafa;
        left: 100%;
        opacity: 1
    }

    99% {
        border-left: .125rem solid #fafafa;
        left: 1%;
        opacity: 1
    }

    to {
        border-left: 0;
        left: 0;
        opacity: 1
    }
}

@keyframes slide-right {
    0% {
        border-left: .125rem solid #fafafa;
        left: -100%;
        opacity: 1
    }

    99% {
        border-left: .125rem solid #fafafa;
        left: 1%;
        opacity: 1
    }

    to {
        border-left: 0;
        left: 0;
        opacity: 1
    }
}

.swipable-slider {
    margin-bottom: 1rem;
    text-align: center
}

.slide-selector {
    background: #fafafa;
    border: .0625rem solid #fafafa;
    border-radius: 50%;
    cursor: pointer;
    display: inline-block;
    height: .5rem;
    margin: 0 .25rem;
    width: .5rem
}

.slide-selector.active-slide-selector {
    background: #001eff
}

#table .table-content>*:not(table) {
    display: none!important
}

#table .table-content>table {
    display: block;
    font-size: .984375rem;
    line-height: 1.5;
    overflow-x: auto;
    white-space: nowrap
}

#table .table-content th,#table .table-content td {
    border-bottom: .125rem solid #fafafa;
    border-right: .125rem solid #fafafa;
    border-top: 0;
    white-space: normal
}

#table .table-content .table-with-headers th,#table .table-content .table-with-headers td {
    border-right-color: #fff
}

#table .table-content tr {
    position: relative
}

#table .table-content thead tr th {
    min-width: 14.175rem;
    padding: 1.25rem
}

@media (max-width: 768px) {
    #table .table-content thead tr th {
        padding-top:5.25rem;
        text-align: left
    }
}

#table .table-content .table-with-headers thead tr th {
    color: #fff
}

@media (max-width: 768px) {
    #table .table-content .table-with-headers thead tr th {
        max-width:calc(60vw - 1rem);
        min-width: calc(60vw - 1rem)
    }
}

#table .table-content .table-with-headers thead tr th:nth-of-type(odd) {
    background-color: #999
}

#table .table-content .table-with-headers thead tr th:nth-of-type(2n) {
    background-color: #6578ff
}

#table .table-content .table-with-headers thead tr th:first-child {
    background-color: #fff
}

@media (max-width: 768px) {
    #table .table-content .table-with-headers thead tr th:first-child {
        max-width:calc(35vw - 1rem)!important;
        min-width: calc(35vw - 1rem)!important
    }
}

#table .table-content .table-with-headers thead tr th:only-of-type {
    width: auto
}

#table .table-content tbody tr th {
    font-size: .875rem;
    padding: 2.5rem 1.25rem 2.5rem .5rem;
    vertical-align: middle
}

#table .table-content tbody tr td {
    padding: 2.5rem 1.25rem
}

@media (max-width: 768px) {
    #table .table-content .table-with-headers tbody tr th {
        max-width:calc(35vw - 1rem)!important;
        min-width: calc(35vw - 1rem)!important;
        vertical-align: top
    }
}

@media (max-width: 768px) {
    #table .table-content .table-with-headers tbody tr td {
        max-width:calc(60vw - 1rem);
        min-width: calc(60vw - 1rem)
    }
}

#table .table-content .table-with-headers tbody tr td:nth-of-type(odd) {
    background-color: #e6e6e6
}

#table .table-content .table-with-headers tbody tr td:nth-of-type(2n) {
    background-color: #f0eeea
}

#table .table-content.table-on-mobile {
    background-color: #fff;
    box-shadow: .5rem .25rem .75rem #0006;
    display: block;
    width: calc(35vw - .875rem)
}

#table .table-content.table-on-mobile>table:not(.table-with-headers) {
    display: none
}

#table .table-content.table-on-mobile>table.table-with-headers {
    overflow: hidden
}

#table .table-content.table-on-mobile th,#table .table-content.table-on-mobile td {
    opacity: 0
}

#table .table-content.table-on-mobile tbody th {
    opacity: 1
}

.sort-option:hover {
    text-decoration: underline
}

th {
    border-bottom: .25rem solid transparent;
    border-top: .25rem solid transparent;
    position: relative;
    vertical-align: top
}

th.active-head {
    border-top: .25rem solid #001eff;
    color: #001eff
}


th .sort-option {
    color: #4d4d4d;
    font-weight: 400;
    white-space: normal
}

th .dropmenu {
    bottom: 0;
    display: block;
    position: absolute
}

th .dropdown.expanded ul {
    background: #ffffff;
    border: .0625rem solid #b2b2b2;
    display: block;
    left: -.063rem;
    padding: 0;
    position: absolute;
    right: auto;
    top: .175rem;
    width: 100%
}

th .dropdown.expanded li {
    line-height: 1.5em;
    padding: .5rem
}

th .dropdown.expanded .active-sort {
    background: #fafafa
}

th .reverse.indented:before {
    top: -.25rem;
    transform: rotate(270deg)
}

.table>:not(caption)>*>* {
    padding-left: .75rem;
    padding-right: .75rem
}

.table th.headline:first-child {
    padding-left: .85rem
}

.table thead th {
    border-bottom: 0
}

.table th,.table td {
    border-top: 0;
    padding-top: .75rem;
    padding-bottom: .75rem;
    border-bottom: .125rem solid #fafafa
}

.table td,.table tr {
    padding: 0 .85rem
}

.rich-text-wrapper td,.rich-text-wrapper th {
    padding: .85rem
}

.etf-risers-table.swipable-table .td-with-link {
    padding-right: 0
}

@media (max-width: 1144px) {
    .etf-risers-table.swipable-table .td-with-link .dash-link {
        display:block;
        height: 100%;
        padding: .5rem;
        width: 100%
    }
}

.recent-issues.swipable-table .td-with-link,.upcoming-issues.swipable-table .td-with-link {
    padding: 0
}

.recent-issues.swipable-table .td-with-link .dash-link,.upcoming-issues.swipable-table .td-with-link .dash-link {
    display: block;
    padding: 1rem .5rem;
    width: 100%
}

@media (max-width: 1144px) {
    .recent-issues.swipable-table .td-with-link .dash-link,.upcoming-issues.swipable-table .td-with-link .dash-link {
        padding:.5rem 0
    }
}

.abs-results td {
    vertical-align: top
}

.abs-results td .dash-link {
    display: block;
    height: 100%;
    padding: 0;
    width: 100%
}

.aim-search-list .dash-link,.aim-search-results .dash-link,.indices-table .dash-link {
    padding: 0!important
}

.aim-search-list .dash-link figure,.aim-search-results .dash-link figure,.indices-table .dash-link figure {
    height: 2.45rem
}

.quick-rich-text table {
    background: transparent
}

@media (max-width: 480px) {
    .quick-rich-text table {
        display:block
    }

    .quick-rich-text table td {
        display: block;
        height: auto;
        padding: .5rem .85rem
    }
}

.quick-rich-text td {
    border: 0
}

.instrument-news .rns-news {
    height: 2rem;
    padding: .5rem 0;
    position: relative
}

.instrument-news .rns-news img {
    display: inline-block;
    max-height: 2rem;
    max-width: 100%
}

.instrument-news .rns-news .today-icon {
    animation: blink 1.5s linear infinite alternate-reverse backwards;
    content: "";
    display: block;
    height: 1rem;
    left: -.5rem;
    position: absolute;
    top: -.125rem;
    width: 1rem
}

@keyframes blink {
    0% {
        opacity: 0
    }

    60% {
        opacity: 1
    }

    to {
        opacity: 1
    }
}

.fb-page.fb_iframe_widget_fluid,.fb-page.fb_iframe_widget_fluid>span,.fb-page.fb_iframe_widget_fluid>span>iframe {
    height: 100%!important;
    width: 100%!important
}

.cta-icon {
    margin-right: .5rem;
    max-height: 2rem;
    max-width: 2rem
}


.cta-button {
    margin-bottom: 0
}

@media (max-width: 480px) {
    .cta-button {
        display:block
    }
}

.cta-link .cta-document {
    transform: translateY(25%)
}

.download-document-simple {
    background-color: #f4f4f4;
    color: #000;
    padding: 2rem .5rem .5rem 2rem
}

.documents-title {
    margin-bottom: 2rem
}

.simple-document {
    background-color: #fff;
    color: #000;
    margin-bottom: 1.5rem;
    margin-right: 1.5rem;
    padding: 1rem 1rem 1rem 3.5rem;
    position: relative
}

.simple-document__info {
    display: inline-block
}

.filter-input {
    border: .0625rem solid #b2b2b2;
    border-radius: 2.5rem;
    margin-bottom: 1rem;
    max-width: 21.5rem;
    outline: none;
    padding: .75rem 1.5rem;
    position: relative;
    width: 100%
}

@media (max-width: 480px) {
    .filter-input {
        font-size:.74375rem;
        padding: .75rem 1rem
    }
}

.filter-input:focus {
    box-shadow: none
}

.icon-filters {
    height: 1.5rem;
    margin-left: 0;
    width: 3rem
}

.filter-buttons {
    margin-right: 3rem
}

@media (max-width: 480px) {
    .filter-buttons {
        display:block;
        margin-bottom: 1.5rem;
        margin-right: 0
    }
}

.filter-buttons.news-explorer {
    margin-right: 2rem
}

.filter-buttons button {
    background: #ffffff;
    border: .125rem solid #001eff;
    border-radius: 0;
    color: #001eff;
    line-height: 1rem;
    padding: .75rem 1.25rem
}

@media (max-width: 480px) {
    .filter-buttons button {
        padding:.75rem .5rem;
        width: 33.333%
    }
}

.filter-buttons button+button {
    border-left: 0
}

.filter-buttons button.active {
    background: #001eff;
    color: #fff;
    transform: scale(1.1)
}

@media (max-width: 1023px) {
    .date-label {
        display:block;
        margin: 2rem auto 0
    }
}

.date-container {
    margin: 0 1rem;
    position: relative
}

@media (max-width: 1023px) {
    .date-container {
        margin-left:0
    }
}


.input-container {
    display: inline-block;
    max-width: 21.5rem;
    width: 100%
}

.search-container {
    position: relative
}


@media (max-width: 480px) {
    .search-container:after {
        right:1rem
    }
}

.search-container .filter-input {
    float: left
}

.filter-action-buttons {
    float: right
}

@media (max-width: 1023px) {
    .filter-action-buttons {
        clear:both;
        display: block;
        float: none
    }

    .filter-action-buttons .fake-link {
        display: inline
    }
}

.filter-action-buttons .fake-link {
    display: block
}

.gold-button {
    display: inline-block
}

.index-filter.search-filter,.search-input {
    overflow: visible
}

.index-filter.search-filter:after,.search-input:after {
    clear: both;
    content: "";
    display: block
}

.index-filter.search-filter .input-container,.index-filter.search-filter .filter-input,.search-input .input-container,.search-input .filter-input {
    max-width: 37.5rem
}

.dropdown-filter {
    min-height: 6rem;
    overflow: visible
}

@media (max-width: 480px) {
    .dropdown-filter {
        min-height:14rem
    }
}

.dropdown-filter.index-filter>div {
    width: 33%
}

@media (max-width: 768px) {
    .dropdown-filter.index-filter>div {
        width:100%
    }
}

.ng-select {
    margin-bottom: 1rem
}

.issuers-autocomplete {
    background: #ffffff;
    border: .0625rem solid #b2b2b2;
    left: 1.5rem;
    position: absolute;
    top: 3.3rem;
    width: calc(100% - 3rem);
    z-index: 5
}

.issuers-autocomplete h5 {
    background: #b2b2b2;
    font-size: .875rem;
    margin-bottom: .5rem;
    padding: .5rem 1.5rem
}

.issuers-autocomplete .issuer {
    color: #001eff;
    cursor: pointer;
    margin-bottom: .5rem;
    padding: .5rem 1.5em
}

.issuers-autocomplete .issuer.issuer-empty {
    cursor: initial;
    font-weight: 700;
    margin-bottom: 0
}

.issuers-autocomplete .issuer .issuer-key {
    color: #001eff;
    font-weight: 700
}

.issuers-autocomplete .issuer .issuer-ellipsed {
    max-width: 75%
}

@media (max-width: 480px) {
    .issuers-autocomplete .issuer .issuer-ellipsed {
        max-width:100%
    }
}

.issuers-autocomplete .issuer .issuer-securities,.issuers-autocomplete .issuer.issuer-empty {
    color: #000
}

.issuers-autocomplete .issuer:hover,.issuers-autocomplete .issuer.selected {
    background: #e6e6e6
}

.issuers-autocomplete .issuer:hover .fake-link,.issuers-autocomplete .issuer.selected .fake-link {
    text-decoration: none
}

.issuers-only .issuer:first-child {
    padding-top: 1rem
}

.filter-deselect {
    cursor: pointer;
    display: inline-block;
    height: 1.5rem;
    margin-left: -1rem;
    position: relative;
    top: .25rem;
    width: 1.5rem
}


.filter-wrapper {
    display: inline-block;
    margin-right: 1rem
}

.filters-container .dropdown-filter .ng-dropdown-panel .ng-dropdown-panel-items {
    max-height: 18rem!important
}

.filters-container .ng-select.ng-select-multiple .ng-has-value .ng-placeholder {
    display: block
}

.filters-container .ng-value-icon.left,.filters-container .ng-clear-wrapper {
    display: none
}

.filters-container .ng-select.ng-select-opened .ng-select-container .ng-value-container .ng-placeholder {
    color: #fff
}

.cell-content td {
    border-bottom: auto;
    height: auto
}

.cell-content table {
    background-color: transparent
}

:host ::ng-deep form.form-inline.v-widget-control-bar>div.sigdev-select {
    display: none!important
}

:host ::ng-deep div.v-widget-sigdev-select {
    display: none
}

.widget-error-message-container {
    display: flex;
    flex-direction: row;
    justify-content: center
}

.fdc-widget.fdc-widget-wrapper.advanced-chart-widget.theme--lse .v-single-select .dropdown .dropdown-toggle svg {
    right: .625rem
}

.fdc-widget.fdc-widget-wrapper.advanced-chart-widget.theme--lse .v-widget-input-checkbox .custom-checkbox .custom-control-input:checked:not(:disabled)~.custom-control-label:after {
    background-image: none!important
}

.theme--lse {
    --chart-color-0: #001eff !important;
    --dropdown-button-active-color: #001eff !important;
    --datepicker-bg-hover: #001eff !important;
    --dropdown-link-bg-hover: #001eff !important
}

.fdc-widget.fdc-widget-wrapper.advanced-chart-widget.theme--lse .v-highcharts--styled path.highcharts-point {
    stroke-width: 1px
}

.fdc-widget.fdc-widget-wrapper.advanced-chart-widget.theme--lse .v-widget-dropdown .dropdown .dropdown-menu .dropdown-item.active .dropdown-option-text {
    color: #fff
}

.fdc-widget.fdc-widget-wrapper.advanced-chart-widget.theme--lse .small-control-bar .v-control-bar .dropdown .dropdown-toggle:hover,.fdc-widget.fdc-widget-wrapper.advanced-chart-widget.theme--lse .small-control-bar .v-control-bar .dropdown .dropdown-toggle:focus {
    --dropdown-button-bg-hover: #001eff
}

.fdc-widget.fdc-widget-wrapper.advanced-chart-widget.theme--lse .small-control-bar .v-control-bar .dropdown.show .dropdown-toggle {
    --dropdown-button-bg-active: #001eff
}

.highcharts-tooltip span.ric-name-wrapper {
    margin-top: -.5rem
}

.fdc-widget.fdc-widget-wrapper.advanced-chart-widget.theme--lse .w-advanced-chart-widget .v-highcharts .highstock-container {
    --chart-color-volume-down: #7e1b1b;
    --chart-color-psar-short: #7e1b1b;
    --chart-candlestick-negative: #7e1b1b;
    --chart-ohlc-negative: #7e1b1b;
    --chart-table-bar-negative-color: #7e1b1b
}

.fdc-widget.fdc-widget-wrapper.advanced-chart-widget.theme--lse .v-highcharts.v-highcharts--styled .highstock-container #gradient-0 stop {
    --chart-series-color-0: rgba(22,124,244,.8)
}

.fdc-widget.fdc-widget-wrapper.advanced-chart-widget.theme--lse .v-highcharts.v-highcharts--styled .highstock-container #gradient-0 stop[offset="0"] {
    --chart-stop-opacity-0: .8
}

.fdc-widget.fdc-widget-wrapper.advanced-chart-widget.theme--lse .v-highcharts.v-highcharts--styled .highstock-container #gradient-0 stop[offset="1"] {
    --chart-stop-opacity-1: .3
}

:focus-visible {
    outline: 0
}

.highcharts-label.highcharts-tooltip-box.align-left.highcharts-tooltip-box.highcharts-color-0>span {
    left: 1px!important;
    top: 1px!important
}

* {
    --input-border-color-focus: #001eff !important;
    --multi-select-badge-bg: #001eff !important
}

.fdc-widget.fdc-widget-wrapper.advanced-chart-widget.theme--lse .v-multiselect.v-form-tags__container {
    background-color: transparent!important
}

.fdc-widget.fdc-widget-wrapper.advanced-chart-widget.theme--lse .v-widget-input-date-range .v-widget-input-date .v-date-time-picker .b-form-datepicker .dropdown-menu .b-calendar .b-calendar-grid .b-calendar-grid-body span:not(.disabled).focus,.fdc-widget.fdc-widget-wrapper.advanced-chart-widget.theme--lse .v-widget-input-date-range .v-widget-input-date .v-date-time-picker .b-form-datepicker .dropdown-menu .b-calendar .b-calendar-grid .b-calendar-grid-body span:not(.disabled):focus,.fdc-widget.fdc-widget-wrapper.advanced-chart-widget.theme--lse .v-widget-input-date-range .v-widget-input-date .v-date-time-picker .b-form-datepicker .dropdown-menu .b-calendar .b-calendar-grid .b-calendar-grid-body span:not(.disabled):hover {
    color: var(--datepicker-color-hover)!important
}

#alert-box .page-message {
    color: #fff;
    left: 0;
    margin-top: -3.75rem;
    min-height: 5rem;
    padding: 1.5rem 0;
    position: absolute;
    right: 0;
    width: 100%;
    z-index: 13
}

@media (max-width: 1144px) {
    #alert-box .page-message {
        padding:1.5rem 1rem
    }
}

@media (max-width: 767px) {
    #alert-box .page-message {
        margin-top:0
    }
}

.without-breadcrumbs #alert-box .page-message {
    margin-top: 0
}

#alert-box .page-message a,#alert-box .page-message .fake-link {
    color: #fff
}

#alert-box .page-message__content {
    padding: .5rem 3rem 0;
    position: relative
}

#alert-box .page-message__content:before {
    background-position: center center;
    background-repeat: no-repeat;
    background-size: contain;
    content: "";
    display: block;
    height: 2rem;
    left: 0;
    position: absolute;
    top: 0;
    width: 2rem
}
#alert-box .page-message__links>span {
    margin-right: 1rem
}

@media (max-width: 480px) {
    #alert-box .page-message__links>span {
        display:block;
        margin-bottom: .5rem;
        margin-right: 0
    }
}

#alert-box .page-message.error-page {
    background-color: #7e1b1b
}
