header {
    width: 100%;
    border-bottom: 1px solid #eee;
    padding: 1rem 2rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.logo {
    font-weight: 700;
    font-size: 1.4rem;
    text-transform: uppercase;
    letter-spacing: 1px;
}

nav ul {
    list-style: none;
    display: flex;
    gap: 2rem;
}

nav li {
    font-size: 1rem;
    font-weight: 400;
}

/* Всплывающая строка (уведомление) */
#notification-bar {
    /* position: fixed; */
    /* top: 0; */
    /* left: 0; */
    width: 100%;
    background-color: #141c25;
    /* Чёрный фон */
    color: #fff;
    /* Белый текст */
    margin-bottom: 2rem;
    text-align: center;
    padding: 0.7rem 1rem;
    display: none;
    /* Изначально скрыто */
    z-index: 9999;
}

#notification-bar span {
    font-size: 0.95rem;
}

/* Основной блок контента */
.main {
    max-width: 960px;
    /* margin: 6rem auto 2rem auto; отступ сверху побольше из-за уведомления */
    padding: 0 2rem;
}

.topmain {
    text-align: center;
}

h1 {
    font-size: 2rem;
    margin-bottom: 1rem;
    font-weight: 700;
    letter-spacing: 0.5px;
}

p {
    font-size: 1rem;
    line-height: 1.6;
    margin-bottom: 1.5rem;
}

/* Кнопка */
.btn {
    display: inline-block;
    padding: 0.8rem 1.5rem;
    background-color: #141c25;
    color: #fff;
    text-transform: uppercase;
    font-weight: 600;
    letter-spacing: 0.5px;
    transition: opacity 0.2s ease;
}

.btn:hover {
    opacity: 0.8;
}

/* Дополнительные блоки (пример) */
.cards {
    display: flex;
    gap: 2rem;
    margin-top: 3rem;
}

@media screen and (max-width: 768px) {
    .cards {
        flex-direction: column;
    }
}

.card {
    flex: 1;
    border: 1px solid #eee;
    padding: 1.5rem;
}

.card h3 {
    font-size: 1.2rem;
    margin-bottom: 0.7rem;
}

.card p {
    font-size: 0.95rem;
    color: #333;
}

/* Подвал (footer) */
footer {
    border-top: 1px solid #eee;
    padding: 1rem 2rem;
    text-align: center;
    font-size: 0.8rem;
    color: #666;
    margin-top: 3rem;
}

* {
    font-family: 'Roboto', sans-serif !important;
}
